import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { get } from "../../api/axios";
import { Pagination } from "antd";

const Statistics = () => {
  // ant-table-content
  const columns = [
    {
      title: " كود الوحدة ",
      render: ({property_code, property_name}) => (
        <span>
            {property_name || "-"} <br /><br/> {property_code || "-"}
        </span>
      ),
    },
    {
      title: " تاريخ نشر الوحدة ",
      dataIndex: "property_created_at",
    },

    {
      title: " الحجوزات المؤكدة ",
      dataIndex: "reservations_confirmed",
    },
    {
      title: " الحجوزات الملغية ",
      dataIndex: "reservations_cancelled",
    },
    {
      title: " الحجوزات المنتهية ",
      dataIndex: "reservations_ended",
    },
    {
      title: " مجموع الحجوزات ",
      dataIndex: "reservations_total",
    },

    {
      title: " نظافة المكان ",
      render: ({
        property_rates_total_rating_clean_number,
        property_rates_total_rating_clean_text,
      }) => (
        <span>
          {property_rates_total_rating_clean_text} <br />{" "}
          {property_rates_total_rating_clean_number}
        </span>
      ),
    },
    {
      title: " حالة المكان ",
      render: ({
        property_rates_total_rating_new_number,
        property_rates_total_rating_new_text,
      }) => (
        <span>
          {property_rates_total_rating_new_text} <br />{" "}
          {property_rates_total_rating_new_number}
        </span>
      ),
    },
    {
      title: " خدمة المضيف ",
      render: ({
        property_rates_total_rating_service_number,
        property_rates_total_rating_service_text,
      }) => (
        <span>
          {property_rates_total_rating_service_text} <br />{" "}
          {property_rates_total_rating_service_number}
        </span>
      ),
    },
    {
      title: " دقة الوصف ",
      render: ({
        property_rates_total_rating_matching_number,
        property_rates_total_rating_matching_text,
      }) => (
        <span>
          {property_rates_total_rating_matching_text} <br />{" "}
          {property_rates_total_rating_matching_number}
        </span>
      ),
    },
    {
      title: " الجودة والسعر ",
      render: ({
        property_rates_total_rating_pricing_number,
        property_rates_total_rating_pricing_text,
      }) => (
        <span>
          {property_rates_total_rating_pricing_text} <br />{" "}
          {property_rates_total_rating_pricing_number}
        </span>
      ),
    },
    {
      title: " التقييم العام ",
      render: ({
        property_rates_total_rating_number,
        property_rates_total_rating_text,
      }) => (
        <span>
          {property_rates_total_rating_text} <br />{" "}
          {property_rates_total_rating_number}
        </span>
      ),
    },

    {
      title: " عدد التقييمات ",
      dataIndex: "rates_total_count",
    },
    {
      title: " عدد التعليقات علي الوحدة ",
      dataIndex: "rates_comments_count",
    },
    {
      title: " عدد الردود علي التعليقات ",
      dataIndex: "rates_replies_count",
    },

    {
      title: " عدد المشاهدات ",
      dataIndex: "views_count",
    },
    {
      title: " عدد الزيارات ",
      dataIndex: "views_count",
    },
    {
      title: " عدد النقرات ",
      dataIndex: "clicks_count",
    },

    {
      title: " عدد المحادثات ",
      dataIndex: "chats_count",
    },

    {
      title: " عدد الرودو علي المحادثات ",
      dataIndex: "chats_replies",
    },
    {
      title: " اجمالي زمن الانتظار بالساعة ",
      dataIndex: "chats_wait_time",
    },

    {
      title: " معدل عدد التقييمات لعدد الحجوازات ",
      render: ({
                 rates_reservations_avg,
      }) => (
        <span>
          {rates_reservations_avg}
        </span>
      ),
    },
    {
      title: " معدل عدد التعليقات لعدد الحجوازات ",
      render: ({
                 comments_reservations_avg
      }) => (
        <span>
          {comments_reservations_avg}
        </span>
      ),
    },
    {
      title: " معدل عدد الردود لعدد التعليقات ",
      render: ({
                 replies_reservations_avg
      }) => (
        <span>
          {replies_reservations_avg}
        </span>
      ),
    },
    {
      title: " معدل عدد ردود المضيف علي المحادثات ",
      render: ({replies_chat_avg}) => (
          <span>
          {replies_chat_avg}
        </span>
      ),
    },
    {
      title: " معدل سرعة الرد علي المحادثات بالساعة ",
      render: ({wait_time_avg}) => (
        <span>
          {wait_time_avg}
        </span>
      ),
    },
    {
      title: " معدل تحول المشاهدات إلى نقرة         ",
      render: ({
        view_to_click_avg
      }) => (
        <span>
          {view_to_click_avg}
        </span>
      ),
    },
    {
      title: " معدل تحول النقرة إلى حجز ",
      render: ({
        click_to_reservation_avg
      }) => (
        <span>
          {click_to_reservation_avg}
        </span>
      ),
    },

    // {
    //   title: " نقاط الوحدة ",
    //   dataIndex: "property_point",
    // },
    // {
    //   title: " مستوى الوحدة  ",
    //   render: ({ property_point }) => <> {property_point} % </>,
    // },
  ];
  const [dataFirst, setdataFirst] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("get-units-statistics").then((res) => {
      setdataFirst(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("dataFirst", dataFirst);
    const newarr = [];
    dataFirst.map((item) => {
      const newObj = {};

      // item
      newObj.property_code = item.property.code;
      newObj.property_name = item.property.name;
      newObj.property_created_at = item.property.created_at;

      // item
      newObj.reservations_confirmed = item.reservations.confirmed;
      newObj.reservations_cancelled = item.reservations.cancelled;
      newObj.reservations_ended = item.reservations.ended;
      newObj.reservations_total = item.reservations.total;

      // item
      newObj.property_rates_total_rating_clean_number =
        item.property_rates.total_rating_clean.number;
      newObj.property_rates_total_rating_clean_text =
        item.property_rates.total_rating_clean.text;

      newObj.property_rates_total_rating_new_number =
        item.property_rates.total_rating_new.number;
      newObj.property_rates_total_rating_new_text =
        item.property_rates.total_rating_new.text;

      newObj.property_rates_total_rating_service_number =
        item.property_rates.total_rating_service.number;
      newObj.property_rates_total_rating_service_text =
        item.property_rates.total_rating_service.text;

      newObj.property_rates_total_rating_matching_number =
        item.property_rates.total_rating_matching.number;
      newObj.property_rates_total_rating_matching_text =
        item.property_rates.total_rating_matching.text;

      newObj.property_rates_total_rating_pricing_number =
        item.property_rates.total_rating_pricing.number;
      newObj.property_rates_total_rating_pricing_text =
        item.property_rates.total_rating_pricing.text;

      newObj.property_rates_total_rating_number =
        item.property_rates.total_rating.number;
      newObj.property_rates_total_rating_text =
        item.property_rates.total_rating.text;

      // item
      newObj.rates_total_count = item.rates.total_count;
      newObj.rates_comments_count = item.rates.comments_count;
      newObj.rates_replies_count = item.rates.replies_count;

      newObj.views_count = item.views_count;
      newObj.clicks_count = item.clicks_count;

      // item
      newObj.chats_count = item.chats.count;
      newObj.chats_replies = item.chats.replies;
      newObj.chats_wait_time = item.chats.wait_time;

      // item
      newObj.rates_reservations_avg = item.rates_reservations_avg;

      newObj.comments_reservations_avg =
        item.comments_reservations_avg;

      newObj.replies_reservations_avg =
        item.replies_reservations_avg;

      newObj.replies_chat_avg = item.replies_chat_avg;

      newObj.wait_time_avg = item.wait_time_avg;

      newObj.view_to_click_avg = item.view_to_click_avg;

      newObj.click_to_reservation_avg =
        item.click_to_reservation_avg;

      newObj.property_point = item.property_point;

      newarr.push(newObj);
    });
    setdata(newarr);
  }, [dataFirst]);

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`get-units-statistics?page=${pageNumber}`).then((res) => {
      setdataFirst(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                x: 2550,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
