import React, { useEffect, useState } from "react";
import { Table, Button, Input } from "antd";
import { del, get } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { checkPermission } from "../auth/ProtectedRoutes";
import moment from "moment";
import SearchBar from "../../utils/SearchBar";

const Owners = () => {
  const columns = [
    {
      title: "رقم الضيف ",
      dataIndex: "id",
    },
    {
      title: "تاريخ التسجيل ",
      render: ({ created_at }) => (
        <> {created_at}</>
        // {moment(created_at).utc().format("DD/MM/YYYY")}
      ),
    },
    {
      title: (
        <>
          الاسم
          <br />
          البريد الإلكتروني
          <br />
          الجوال
        </>
      ),
      render: ({ name, phone, email }) => (
        <>
          {name}
          <br />
          {email}
          <br />
          {phone}
        </>
      ),
    },
    {
      title: (
        <>
          العنوان
          <br />
          الجنس
          <br />
          تاريخ الميلاد
        </>
      ),
      render: ({ address, dob, gender }) => (
        <>
          {address}
          <br />
          {gender}
          <br />
          {dob}
        </>
      ),
    },
    {
      title: (
        <>
          الجنسية
          <br />
          نوع الإقامة
          <br />
          رقم الاثبات
        </>
      ),
      render: ({ nationality_id, residence_type, id_number }) => (
        <>
          {nationality_id}
          <br />
          {residence_type}
          <br />
          {id_number}
        </>
      ),
    },
    {
      title: (
        <>
          حجوزاتي المؤكدة
          <br />
          حجوزاتي الملغية
          <br />
          حجوزاتي الكل
        </>
      ),
      render: ({ reservations_count }) => (
        <>
          {reservations_count.confirmed}
          <br />
          {reservations_count.canceled}
          <br />
          {reservations_count.all}
        </>
      ),
    },

    {
      title: "حالة الحساب ",
      dataIndex: "status",
    },
    {
      width: "210px",
      title: (
        <>
          تاريخ الإيقاف \ الحظر
          <br />
          تاريخ نهاية الإيقاف
          <br />
          تاريخ التنشيط
        </>
      ),
      render: ({ log, created_at }) => (
        <>
          {log?.created_at ? <> {created_at} </> : "-"}
          <br />
          {log?.block_to ? <> {log.block_to} </> : "-"}
          <br />
          {created_at}
        </>
      ),
    },
  ];

  if (checkPermission("update-owner") || checkPermission("delete-owner")) {
    columns.push(
      {
        title: " تعديل البريد الإلكتروني",
        dataIndex: "id",
        render: (id) => (
          <button className="btn btn-outline-warning btn-sm p-0">
            <Link
              className="color-inherit text-decoration-none py-1 px-2 d-block"
              to={`/owners/edit/${id}`}
            >
              <EditOutlined />
              تعديل
            </Link>
          </button>
        ),
      },
      {
        title: "تغيير الحالة ",
        dataIndex: "id",
        render: (id) => (
          <button className="btn btn-outline-warning btn-sm p-0">
            <Link
              className="color-inherit text-decoration-none py-1 px-2 d-block"
              to={`/owners/change-status/${id}`}
            >
              <EditOutlined />
              تعديل
            </Link>
          </button>
        ),
      }
    );
  }

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`owners?page=1&search=${searchQuery}`).then((res) => {
      setdata(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  const handleDelet = (id) => {
    del(`owners/${id}`);
    let dateFilter = data.filter((item) => item.id !== id);
    setdata(dateFilter);
  };

  const confirm = (id) => {
    console.log(id);
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = (e) => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };
  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`owners?page=${pageNumber}`).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };
  const handleSearch = (value) => {
    setSearchQuery(value);
    console.log(value);
  };
  return (
    <div>
      <div className="row">
        {checkPermission("create-owner") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
              className="text-decoration-none d-block h-100"
              to="/owners/add"
            >
              <Button type="primary" block>
                {" "}
                إضافة{" "}
              </Button>
            </Link>
          </div>
        )}
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                x: 1650,
                y: "65vh",
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Owners;
