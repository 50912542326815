import React, { useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Form,
  DatePicker,
  Spin,
} from "antd";
import { postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const { Title } = Typography;
const { TextArea } = Input;

const ChangeStatusProperties = () => {
  const navigate = useNavigate();
  let { code } = useParams(); // code comes from the URL params
  const [inputsValue, setInputsValue] = useState({
    reason: "",
    code: code,
    block: 1,
    block_to: "",
  });
  const [loading, setLoading] = useState(false);
  const [fromLoading, setFromLoading] = useState(false);

  // Handle status selection
  const handelStatusUser = (value) => {
    setInputsValue({ ...inputsValue, code: value }); // Set code as string, not an object
  };

  // Handle input changes
  const handleChange = (e) => {
    setInputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  // Handle DatePicker change
  const handleBlockTo = (date, dateString) => {
    setInputsValue({ ...inputsValue, block_to: dateString });
  };

  // Handle form submission
  const onFinish = () => {
    setLoading(true);
    postFromData("properties/block", inputsValue)
      .then(() => {
        message.success("تم التعديل بنجاح");
        setLoading(false);
        navigate("/properties");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || "حدث خطأ ما!");
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        تغيير حالة العقار
      </Title>

      {!fromLoading ? (
        <Form
          onFinish={onFinish}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-4">
              <Form.Item
                label=" ايقاف الى  "
                rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
                name="block_to"
              >
                <DatePicker
                  placeholder="تحديد تاريخ"
                  className="w-100"
                  onChange={handleBlockTo}
                />
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                label="السبب"
                // rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
                name="reason"
              >
                <TextArea
                  rows={1}
                  id="reason"
                  onChange={handleChange}
                  placeholder="اكتب السبب"
                />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 mt-3 offset-lg-4">
              <Form.Item wrapperCol={{ offset: 1, span: 16 }}>
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default ChangeStatusProperties;
