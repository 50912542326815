import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Space,
  message,
  Spin,
  Form,
  Divider,
  Checkbox,
  Radio,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { get, post } from "../../../../api/axios";
import Steper from "../layoutProperty/Steper";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const Step3 = () => {
  const navigate = useNavigate();

  const [fromloading, setFromLoading] = useState(false);

  const [inputsValue, setinputsValue] = useState({});

  const [dataCities, setDataCities] = useState([]);
  const [option, setOption] = useState([]);
  const [optionsSuitableGuests, setOptionsSuitableGuests] = useState([]);
  const [optionsSuitableGuestsData, setOptionsSuitableGuestsData] = useState(
    []
  );
  const [optionskitchens, setOptionskitchens] = useState([]);
  const [optionskitchenMachines, setOptionskitchenMachines] = useState([]);
  const [optionskitchenMachinesData, setOptionskitchenMachinesData] = useState(
    []
  );
  const [optionskitchensData, setOptionskitchensData] = useState([]);
  const [optionswashingRoomFacilities, setOptionswashingRoomFacilities] =
    useState([]);
  const [
    optionswashingRoomFacilitiesData,
    setOptionswashingRoomFacilitiesData,
  ] = useState([]);
  const [optionswashingRoomMachines, setOptionswashingRoomMachines] = useState(
    []
  );
  const [optionsattractiveFacility, setOptionsattractiveFacility] = useState(
    []
  );
  const [optionsattractiveFacilityData, setOptionsattractiveFacilityData] =
    useState([]);
  const [optionswashingRoomMachinesData, setOptionswashingRoomMachinesData] =
    useState([]);
  const [optionscomfortFacility, setOptionscomfortFacility] = useState([]);
  const [optionscomfortFacilityData, setOptionscomfortFacilityData] = useState(
    []
  );
  const [optionsluxuryFacility, setOptionsluxuryFacility] = useState([]);
  const [optionsluxuryFacilityData, setOptionsluxuryFacilityData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [bedState, setBedState] = useState({
    bedRooms: "",
    singleBed: "",
    doubleBed: "",
  });
  const [sectionValidCheckBoxes, setSectionValidCheckBoxes] = useState({
    washroom: false,
    kitchen: false,
    bathrooms: false,
    pools: false,
  });

  // validation function to open the select box for it's section
  const validationInputToOpen = (e) => {
    if (!e.target) return;

    const { value, name } = e.target;

    setSectionValidCheckBoxes((prev) => ({
      ...prev,
      [name]: value?.trim()?.length ? true : false,
    }));
  };

  const validationRadio = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    // Update the state to reflect the selection
    setSectionValidCheckBoxes((prev) => ({
      ...prev,
      [name]: value !== "", // true if any value is selected
    }));
  };

  useEffect(() => {
    setOptionskitchensData(
      optionskitchens.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionskitchens]);
  useEffect(() => {
    setOption(
      dataCities.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [dataCities]);
  useEffect(() => {
    setOptionsSuitableGuestsData(
      optionsSuitableGuests.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionsSuitableGuests]);
  useEffect(() => {
    setOptionskitchenMachinesData(
      optionskitchenMachines.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionskitchenMachines]);
  useEffect(() => {
    setOptionswashingRoomFacilitiesData(
      optionswashingRoomFacilities.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionswashingRoomFacilities]);
  useEffect(() => {
    setOptionswashingRoomMachinesData(
      optionswashingRoomMachines.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionswashingRoomMachines]);
  useEffect(() => {
    setOptionsattractiveFacilityData(
      optionsattractiveFacility.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionsattractiveFacility]);
  useEffect(() => {
    setOptionscomfortFacilityData(
      optionscomfortFacility.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionscomfortFacility]);
  useEffect(() => {
    setOptionsluxuryFacilityData(
      optionsluxuryFacility.map((d) => ({
        value: d.id.toString(),
        label: d.name_ar,
      }))
    );
  }, [optionsluxuryFacility]);

  useEffect(() => {
    if (localStorage.getItem("codeAddProperty")) {
      get("get-facilities").then((res) => {
        let groupedFacilities = res.data;
        setFromLoading(true);
        Object.keys(groupedFacilities).map(function (index, groupValues) {
          switch (groupValues) {
            case 1: //bathroom
              setOptionsSuitableGuests(groupedFacilities[groupValues]);
              break;
            case 2: //kitchen
              setOptionskitchens(groupedFacilities[groupValues]);
              break;
            case 3: //kitche machines
              setOptionskitchenMachines(groupedFacilities[groupValues]);
              break;
            case 4: //washing room facilities
              setOptionswashingRoomFacilities(groupedFacilities[groupValues]);
              break;
            case 5: //washing room machines
              setOptionswashingRoomMachines(groupedFacilities[groupValues]);
              break;
            case 6: //attractive facility
              setOptionsattractiveFacility(groupedFacilities[groupValues]);
              break;
            case 7: //comfort facility
              setOptionscomfortFacility(groupedFacilities[groupValues]);
              break;
            case 8: //luxury facility
              setOptionsluxuryFacility(groupedFacilities[groupValues]);
              break;
            default:
              break;
          }
        });
        console.log("start step 3");
      });
    } else {
      navigate("/add-property/step-1");
    }
  }, []);
  const onFinish = (values) => {
    setLoading(true);

    const completeValues = {
      code: values.code,
      seats: {
        "مجلس رئيسي": values.seats["مجلس رئيسي"].map(item => item['عدد_الأشخاص']),
        "جلسة خارجية": values.seats["جلسة خارجية"][0] ? [values.seats["جلسة خارجية"][0]['عدد_الأشخاص']] : [],
        "ملحق خارجي": values.seats["ملحق خارجي"][0] ? [values.seats["ملحق خارجي"][0]['عدد_الأشخاص']] : [],
        "خـيـمـة": values.seats["خـيـمـة"][0] ? [values.seats["خـيـمـة"][0]['عدد_الأشخاص']] : [],
        "جلسة عريش": values.seats["جلسة عريش"] ? [values.seats["جلسة عريش"][0]['عدد_الأشخاص']] : [],
        "بيت شعر": values.seats["بيت شعر"] ? [values.seats["بيت شعر"][0]['عدد_الأشخاص']] : [],
        "مسطح اخضر": values.seats["مسطح اخضر"] ? [values.seats["مسطح اخضر"][0]['عدد_الأشخاص']] : []
      },
      rooms: {
        "غرف النوم": values.rooms["غرف النوم"],
        "سرير مفرد": values.rooms["سرير مفرد"],
        "سرير مزدوج": values.rooms["سرير مزدوج"]
      },
      bathrooms: {
        "دورات المياه": values.bathrooms["دورات المياه"],
        "مستلزمات دورات المياه": values.bathrooms["مستلزمات دورات المياه"]
      },
      kitchens: {
        "المطابخ": values.kitchens["المطابخ"],
        "الاجهزة": values.kitchens["الاجهزة"],
        "طاولة الطعام": values.kitchens["طاولة الطعام"]
      },
      washingRooms: {
        "غرف غسيل الملابس": values.washingRooms["غرف غسيل الملابس"],
        "مستلزمات غرفة الغسيل": values.washingRooms["مستلزمات غرفة الغسيل"]
      },
      washingRoomsMachine: values.washingRooms["الاجهزة والآلات"],
      attractiveFacility: values.attractiveFacility["المرافق الجاذبة"] ? values.attractiveFacility["المرافق الجاذبة"] : [],
      comfortFacility: values.comfortFacility["الخدمات"] ? values.comfortFacility["الخدمات"] : [],
      luxuryFacility: values.luxuryFacility["الرفاهية"] ? values.luxuryFacility["الرفاهية"] : [],
      pools: values.pools.map(pool => ({
        internal: pool.internal,
        barrier: pool.barrier,
        flat: pool.flat,
        property: pool.property,
        height: pool.height,
        width: pool.width,
        depth: pool.depth
      }))
    };

    console.log(completeValues, values);
    // setLoading(false);
    // postFromData
    post("add-property/step-3", completeValues)
      .then((res) => {
        setLoading(false);
        navigate("/add-property/step-4");
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="add-prop step-3">
      <Steper current={3} />

      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          scrollToFirstError={true}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            // "city_id": inputsValue.city_id,
            code: localStorage.getItem("codeAddProperty"),
            // "suitable_guests": inputsValue.bathrooms['مستلزمات دورات المياه'],
            seats: inputsValue.seats
              ? {
                  "مجلس رئيسي": inputsValue.seats["مجلس رئيسي"]
                    ? inputsValue.seats["مجلس رئيسي"]
                    : [[]],
                  "جلسة خارجية": inputsValue.seats["جلسة خارجية"]
                    ? inputsValue.seats["جلسة خارجية"]
                    : [[]],
                  "ملحق خارجي": inputsValue.seats["ملحق خارجي"]
                    ? inputsValue.seats["ملحق خارجي"]
                    : [[]],
                  خـيـمـة: inputsValue.seats["خـيـمـة"]
                    ? inputsValue.seats["خـيـمـة"]
                    : [[]],
                  "جلسة عريش": inputsValue.seats["جلسة عريش"]
                    ? inputsValue.seats["جلسة عريش"]
                    : [[]],
                  "بيت شعر": inputsValue.seats["بيت شعر"]
                    ? inputsValue.seats["بيت شعر"]
                    : [[]],
                  "مسطح اخضر": inputsValue.seats["مسطح اخضر"]
                    ? inputsValue.seats["مسطح اخضر"]
                    : [[]],
                }
              : {
                  "مجلس رئيسي": [[]],
                  "جلسة خارجية": [[]],
                  "ملحق خارجي": [[]],
                  خـيـمـة: [[]],
                  "جلسة عريش": [[]],
                  "بيت شعر": [[]],
                  "مسطح اخضر": [[]],
                },
            pools: inputsValue.pools
              ? inputsValue.pools
              : [
                  {
                    property: "",
                    internal: "",
                    barrier: "",
                    flat: "",
                    height: "",
                    width: "",
                    depth: "",
                  },
                ],
            rooms: inputsValue.rooms
              ? {
                  "غرف النوم": inputsValue.rooms["غرف النوم"]
                    ? inputsValue.rooms["غرف النوم"]
                    : "",
                  "سرير مفرد": inputsValue.rooms["سرير مفرد"]
                    ? inputsValue.rooms["سرير مفرد"]
                    : "",
                  "سرير مزدوج": inputsValue.rooms["سرير مزدوج"]
                    ? inputsValue.rooms["سرير مزدوج"]
                    : "",
                }
              : {
                  "غرف النوم": "",
                  "سرير مفرد": "",
                  "سرير مزدوج": "",
                },
            bathrooms: inputsValue.bathrooms
              ? {
                  "دورات المياه": inputsValue.bathrooms["دورات المياه"]
                    ? inputsValue.bathrooms["دورات المياه"]
                    : "",
                  "مستلزمات دورات المياه": inputsValue.bathrooms[
                    "مستلزمات دورات المياه"
                  ]
                    ? inputsValue.bathrooms["مستلزمات دورات المياه"]
                    : [],
                }
              : {
                  "دورات المياه": "",
                  "مستلزمات دورات المياه": [],
                },
            kitchens: inputsValue.kitchens
              ? {
                  المطابخ: inputsValue.kitchens["المطابخ"]
                    ? inputsValue.kitchens["المطابخ"]
                    : "",
                  "طاولة الطعام": inputsValue.kitchens["طاولة الطعام"]
                    ? inputsValue.kitchens["طاولة الطعام"]
                    : "",
                  "مستلزمات غرفة الغسيل": inputsValue.kitchens[
                    "مستلزمات غرفة الغسيل"
                  ]
                    ? inputsValue.kitchens["مستلزمات غرفة الغسيل"]
                    : [[]],
                  الاجهزة: inputsValue.kitchens["الاجهزة"]
                    ? inputsValue.kitchens["الاجهزة"]
                    : [[]],
                }
              : {
                  المطابخ: "",
                  "طاولة الطعام": "",
                  "مستلزمات غرفة الغسيل": [[]],
                  الاجهزة: [[]],
                },
            washingRooms: inputsValue.washingRooms
              ? {
                  "غرف غسيل الملابس": inputsValue.washingRooms[
                    "غرف غسيل الملابس"
                  ]
                    ? inputsValue.washingRooms["غرف غسيل الملابس"]
                    : "",
                  "مستلزمات غرفة الغسيل": inputsValue.washingRooms[
                    "مستلزمات غرفة الغسيل"
                  ]
                    ? inputsValue.washingRooms["مستلزمات غرفة الغسيل"]
                    : [[]],
                  "الاجهزة والآلات": inputsValue.washingRooms["الاجهزة والآلات"]
                    ? inputsValue.washingRooms["الاجهزة والآلات"]
                    : [[]],
                }
              : {
                  "غرف غسيل الملابس": "",
                  "مستلزمات غرفة الغسيل": [],
                  "الاجهزة والآلات": [],
                },
            attractiveFacility: inputsValue.attractiveFacility
              ? {
                  "المرافق الجاذبة": inputsValue.attractiveFacility[
                    "المرافق الجاذبة"
                  ]
                    ? inputsValue.attractiveFacility["المرافق الجاذبة"]
                    : [[]],
                }
              : {
                  "المرافق الجاذبة": [],
                },
            comfortFacility: inputsValue.comfortFacility
              ? {
                  الخدمات: inputsValue.comfortFacility["الخدمات"]
                    ? inputsValue.comfortFacility["الخدمات"]
                    : [[]],
                }
              : {
                  لخدمات: [],
                },
            luxuryFacility: inputsValue.luxuryFacility
              ? {
                  // "الرفاهية": ['61', '62', '63', 64]
                  الرفاهية: inputsValue.luxuryFacility["الرفاهية"]
                    ? inputsValue.luxuryFacility["الرفاهية"]
                    : [[]],
                }
              : {
                  // "الرفاهية": ['61', '62', '63', 64]
                  الرفاهية: [],
                },
          }}
          autoComplete="off"
        >
          <div className="row">
            <Form.Item name="code" className="d-none">
              <Input />
            </Form.Item>

            <Form.List name="seats">
              {() => (
                <>
                  <Divider orientation="left"> الجلسات والمجالس </Divider>
                  <div className="col-lg-12">
                    <Form.List name="مجلس رئيسي">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label="مجلس رئيسي                                                        "
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  placeholder="عدد_الأشخاص"
                                />
                              </Form.Item>
                              <MinusCircleOutlined
                                className="ms-1"
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <div className="d-flex justify-content-end">
                            <Button
                              className="text-main"
                              type="link"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                            >
                              أضف مجلس أخر
                            </Button>
                          </div>
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Form.List name="جلسة خارجية">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label="جلسة خارجية                                                        "
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  data-test=""
                                  placeholder="عدد_الأشخاص"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} /> */}
                            </Space>
                          ))}
                          {/* <div className='d-flex justify-content-end'>
                                                            <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                                أضف جلسة خارجية أخر

                                                            </Button>
                                                        </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Form.List name="ملحق خارجي">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label="ملحق خارجي                                                        "
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  data-test=""
                                  placeholder="عدد_الأشخاص"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} /> */}
                            </Space>
                          ))}
                          {/* <div className='d-flex justify-content-end'>
                                                            <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                                أضف ملحق خارجي  أخر

                                                            </Button>
                                                        </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Form.List name="خـيـمـة">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label="خـيـمـة                                                        "
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  data-test=""
                                  placeholder="عدد_الأشخاص"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} /> */}
                            </Space>
                          ))}
                          {/* <div className='d-flex justify-content-end'>
                                                            <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                                أضف خـيـمـة  أخر

                                                            </Button>
                                                        </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Form.List name="جلسة عريش">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label="          جلسة     عريش                                            "
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  data-test=""
                                  placeholder="عدد_الأشخاص"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} /> */}
                            </Space>
                          ))}
                          {/* <div className='d-flex justify-content-end'>
                                                            <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                                أضف جلسة عريش  أخر

                                                            </Button>
                                                        </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Form.List name="بيت شعر">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label="بيت شعر                                                        "
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  data-test=""
                                  placeholder="عدد_الأشخاص"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} /> */}
                            </Space>
                          ))}
                          {/* <div className='d-flex justify-content-end'>
                                                            <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                                أضف بيت شعر  أخر

                                                            </Button>
                                                        </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <Form.List name="مسطح اخضر">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              className="d-flex align-items-baseline"
                            >
                              <Form.Item
                                label=" مسطح اخضر"
                                className="w-100 mb-1"
                                {...restField}
                                name={[name, "عدد_الأشخاص"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="w-100"
                                  data-test=""
                                  placeholder="ادخل المساحه بالمتر"
                                />
                              </Form.Item>
                              {/* <MinusCircleOutlined className='ms-1' onClick={() => remove(name)} /> */}
                            </Space>
                          ))}
                          {/* <div className='d-flex justify-content-end'>
                                                            <Button className='text-main' type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                                                أضف مسطح اخضر   أخر

                                                            </Button>
                                                        </div> */}
                        </>
                      )}
                    </Form.List>
                  </div>
                </>
              )}
            </Form.List>

            <Form.List name="rooms">
              {() => (
                <>
                  <Divider orientation="left"> غرف النوم </Divider>

                  <div className="col-lg-12">
                    <Form.Item
                      label="غرف النوم "
                      name="غرف النوم"
                      rules={[
                        {
                          required: true,
                          message: "هذا الحقل مطلوب!",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" ادخل العدد  "
                        onChange={(e) =>
                          setBedState((prev) => ({
                            ...prev,
                            bedRooms: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-12">
                    <Form.Item
                      label="سرير مفرد"
                      name="سرير مفرد"
                      rules={[
                        bedState.bedRooms && !bedState.doubleBed
                          ? {
                              required: true,
                              message: "هذا الحقل مطلوب!",
                            }
                          : null,
                      ]}
                    >
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" ادخل العدد  "
                        onChange={(e) =>
                          setBedState((prev) => ({
                            ...prev,
                            singleBed: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-12">
                    <Form.Item
                      label="سرير مزدوج"
                      name="سرير مزدوج"
                      rules={[
                        bedState.bedRooms && !bedState.singleBed
                          ? {
                              required: true,
                              message: "هذا الحقل مطلوب!",
                            }
                          : null,
                      ]}
                    >
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" ادخل العدد  "
                        onChange={(e) =>
                          setBedState((prev) => ({
                            ...prev,
                            doubleBed: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>

            <Form.List name="bathrooms">
              {() => (
                <>
                  <Divider orientation="left"> دورات المياه </Divider>
                  <div className="col-lg-12">
                    <Form.Item label="دورات المياه" name="دورات المياه">
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" ادخل  عدد  دورات المياه"
                        name="bathrooms"
                        onChange={validationInputToOpen}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-12">
                    <Form.Item
                      label="مستلزمات دورات المياه"
                      name="مستلزمات دورات المياه"
                    >
                      <Checkbox.Group
                        options={optionsSuitableGuestsData}
                        disabled={!sectionValidCheckBoxes.bathrooms}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>
            <Form.List name="kitchens">
              {() => (
                <>
                  <Divider orientation="left"> المطابخ </Divider>
                  <div className="col-lg-12">
                    <Form.Item label="المطابخ" name="المطابخ">
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" ادخل عدد المطابخ "
                        name="kitchen"
                        onChange={validationInputToOpen}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-12">
                    <Form.Item label=" مستلزمات المطبخ " name="مستلزمات المطبخ">
                      <Checkbox.Group
                        disabled={!sectionValidCheckBoxes.kitchen}
                        options={optionskitchensData}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-12">
                    <Form.Item label="الاجهزة" name="الاجهزة">
                      <Checkbox.Group
                        disabled={!sectionValidCheckBoxes.kitchen}
                        options={optionskitchenMachinesData}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-12">
                    <Form.Item
                      label="طاولة الطعام"
                      name="طاولة الطعام"
                       
                    >
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" عدد الأشخاص "
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>

            <Form.List name="washingRooms">
              {() => (
                <>
                  <Divider orientation="left"> غرف غسيل الملابس </Divider>
                  <div className="col-lg-12">
                    <Form.Item label="غرف غسيل الملابس" name="غرف غسيل الملابس">
                      <Input
                        type="number"
                        className="w-100"
                        placeholder=" ادخل عدد غرف غسيل الملابس "
                        name="washroom"
                        onChange={validationInputToOpen}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-12">
                    <Form.Item
                      label="مستلزمات غرفة الغسيل"
                      name="مستلزمات غرفة الغسيل"
                    >
                      <Checkbox.Group
                        options={optionswashingRoomFacilitiesData}
                        disabled={!sectionValidCheckBoxes.washroom}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-12">
                    <Form.Item label="الاجهزة والآلات" name="الاجهزة والآلات">
                      <Checkbox.Group
                        options={optionswashingRoomMachinesData}
                        disabled={!sectionValidCheckBoxes.washroom}
                      />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>

            <Form.List name="attractiveFacility">
              {() => (
                <>
                  <Divider orientation="left"> المرافق الجاذبة </Divider>

                  <div className="col-lg-12 hide-lable grid-checkbox-group">
                    <Form.Item label="المرافق الجاذبة" name="المرافق الجاذبة">
                      <Checkbox.Group options={optionsattractiveFacilityData} />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>

            <Form.List name="comfortFacility">
              {() => (
                <>
                  <Divider orientation="left"> الخدمات ووسائل الراحة </Divider>

                  <div className="col-lg-12 hide-lable grid-checkbox-group">
                    <Form.Item label="الخدمات ووسائل الراحة" name="الخدمات">
                      <Checkbox.Group options={optionscomfortFacilityData} />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>

            <Form.List name="luxuryFacility">
              {() => (
                <>
                  <Divider orientation="left"> الرفاهية والترفيه </Divider>

                  <div className="col-lg-12 hide-lable grid-checkbox-group">
                    <Form.Item label="الرفاهية" name="الرفاهية">
                      <Checkbox.Group options={optionsluxuryFacilityData} />
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>

            <Divider orientation="left"> المسابح </Divider>
            <Form.List name="pools">
              {(fields, { add, remove }) => (
                <div className="pools-section">
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        {/* <div className="ant-form-text">النوع:</div> <br /> */}
                        <Form.Item
                          className="w-100 "
                          {...restField}
                          name={[name, "property"]}
                           label="حدد نوع المسبح"
                         >
                           <Radio.Group name="pools" onChange={validationRadio}>
                            <Radio value={1}> نسائى </Radio>
                            <Radio value={2}> عام </Radio>
                            <Radio value={3}> اطفال </Radio>
                            <Radio value={4}> بتدفئة </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="w-100 flex flex-col"
                          {...restField}
                          name={[name, "internal"]}
                          label="حدد نوع المسبح"
                        >
                          <Radio.Group disabled={!sectionValidCheckBoxes.pools}>
                            <Radio value={1}> مسبح داخلي </Radio>
                            <Radio value={2}> مسبح خارجي </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="w-100"
                          {...restField}
                          name={[name, "barrier"]}
                          label="test"
                        >
                          <Radio.Group disabled={!sectionValidCheckBoxes.pools}>
                            <Radio value={1}> بحاجز </Radio>
                            <Radio value={2}> بدون حاجز </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="w-100"
                          {...restField}
                          name={[name, "flat"]}
                          label="test"
                        >
                          <Radio.Group disabled={!sectionValidCheckBoxes.pools}>
                            <Radio value={1}> مسطح </Radio>
                            <Radio value={2}> متدرج </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          className="property-input"
                          {...restField}
                          label="test"
                          name={[name, "height"]}
                          rules={
                            sectionValidCheckBoxes.pools
                              ? [
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]
                              : null
                          }
                        >
                          <Input
                            placeholder="الطول"
                            type="number"
                            disabled={!sectionValidCheckBoxes.pools}
                          />
                        </Form.Item>
                        <Form.Item
                          className="property-input"
                          {...restField}
                          label="test"
                          name={[name, "width"]}
                          rules={
                            sectionValidCheckBoxes.pools
                              ? [
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]
                              : null
                          }
                        >
                          <Input
                            placeholder="العرض"
                            type="number"
                            disabled={!sectionValidCheckBoxes.pools}
                          />
                        </Form.Item>
                        <Form.Item
                          className="property-input"
                          {...restField}
                          label="test"
                          name={[name, "depth"]}
                          rules={
                            sectionValidCheckBoxes.pools
                              ? [
                                  {
                                    required: true,
                                    message: "هذا الحقل مطلوب",
                                  },
                                ]
                              : null
                          }
                        >
                          <Input
                            placeholder="العمق"
                            type="number"
                            disabled={!sectionValidCheckBoxes.pools}
                          />
                        </Form.Item>
                        <Button
                          danger
                          className="mb-4"
                          onClick={() => remove(name)}
                        >
                          حذف هذا المسبح
                          <MinusCircleOutlined />
                        </Button>
                      </Space>
                    );
                  })}
                  <Form.Item>
                    <Button
                      className="text-main"
                      type="link"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      أضف مسبح أخر
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-lg-4 offset-lg-1">
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "جاري ..." : "التالي"}
              </Button>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <Button size="large" block>
                <Link
                  className="d-block text-decoration-none"
                  to="/add-property/step-2"
                >
                  {" "}
                  رجوع{" "}
                </Link>
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default Step3;
