import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Table, Tag } from "antd";
import { del, get } from "../../api/axios";
import { Pagination, message } from "antd";
import SearchBar from "../../utils/SearchBar";

const UsersRates = () => {
  // ant-table-content
  const columns = [
    {
      title: " الرقم   ",
      dataIndex: "reservation_number",
    },
    {
      title: " 	رقم  الحجز     ",
      dataIndex: "reservation_number",
    },
    {
      title: "  تاريخ الانشاء ",
      dataIndex: "reservation_date",
      width: "130px",
    },
    {
      title: "    كود الوحدة   ",
      dataIndex: "property_code",
      width: "200px",
    },
    {
      title: "رقم المستخدم (الضيف)",
       width: "200px",
      render: ({user_phone}) => (
        <span>
          {user_phone || "-"} 
        </span>
      ),
    },
    {
      title: "  تاريخ التقييم ",
      dataIndex: "rate_date",
      width: "130px",
    },
    {
      title: "   تعليق الضيف  ",
      dataIndex: "user_comment",
      width: "150px",
    },
    {
      title: " النظافة  ",
      width: "100px",
      render: ({ clean_text, clean_number }) => (
        <span>
          {clean_number} <br /> {clean_text}
        </span>
      ),
    },
    {
      title: " الحالة  ",
      width: "100px",

      render: ({ new_number, new_text }) => (
        <span>
          {new_number} <br /> {new_text}
        </span>
      ),
    },
    {
      title: " الخدمة  ",
      width: "100px",

      render: ({ service_number, service_text }) => (
        <span>
          {service_number} <br /> {service_text}
        </span>
      ),
    },
    {
      title: " دقة الوصف  ",
      width: "100px",

      render: ({ matching_number, matching_text }) => (
        <span>
          {matching_number} <br /> {matching_text}
        </span>
      ),
    },
    {
      title: "  الجودة والسعر ",
      width: "100px",

      render: ({ pricing_number, pricing_text }) => (
        <span>
          {pricing_number} <br /> {pricing_text}
        </span>
      ),
    },
    {
      title: " التقييم العام  ",
      width: "100px",

      render: ({ total_rate_number, total_rate_text }) => (
        <span>
          {total_rate_number} <br /> {total_rate_text}
        </span>
      ),
    },
    {
      title: " رقم المستخدم (المضيف)  ",
      dataIndex: "user_phone",
      width: "200px",
    },
    // #####
    {
      title: (
        <>
          تاريخ الرد
          {/* <br/>
                 رقم المستخدم */}
        </>
      ),
      width: "100px",

      render: ({ reply_date, new_text }) => (
        <span>
          {reply_date} <br />
          {/* {new_text} */}
        </span>
      ),
    },

    {
      title: " رد المضيف علي تعليق الضيف للوحدة  ",
      dataIndex: "reply_owner_comment",
      width: "200px",
    },
    {
      title: "  حالة التقييم ",
      width: "100px",

      render: ({ status }) => (
        <>
          <Tag color={`${status == "محذوف" ? "error" : "success"}`}>
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: "حذف",
      width: "100px",

      render: ({ id, status }) => (
        <Popconfirm
          disabled={status == "محذوف" ? true : false}
          title="حذف العنصر"
          description="هل أنت متأكد من حذف هذه العنصر؟"
          onConfirm={() => confirm(id)}
          onCancel={cancel}
          okText="نعم"
          cancelText="لا"
        >
          <Button danger disabled={status == "محذوف" ? true : false}>
            حذف
          </Button>
        </Popconfirm>
      ),
    },
  ];
  const handleDelet = (id) => {
    del(`delete-user-rate/${id}`);

    const newData = [];
    data.map((item) => {
      if (item.id == id) {
        newData.push({ ...item, status: "محذوف" });
      } else {
        newData.push(item);
      }
    });
    setdata(newData);
  };

  const confirm = (id) => {
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = (e) => {
    console.log(e);
    message.error("تم الغاء الامر");
  };
  const [dataFirst, setdataFirst] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`get-users-rates?search=${searchQuery}`).then((res) => {
      setdataFirst(res?.data?.data);
      setTotal(res?.data?.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  useEffect(() => {
    console.log("dataFirst", dataFirst);
    const newarr = [];
    dataFirst?.map((item) => {
      const newObj = {};

      // item
      newObj.id = item.id;
      newObj.property_code = item.property_code;

      newObj.user_comment = item.user_comment;
      newObj.rate_date = item.rate_date;
      newObj.reservation_number = item.reservation_number;
      newObj.reservation_date = item.reservation_date;

      // item
      newObj.clean_number = item.clean.number;
      newObj.clean_text = item.clean.text;
      // item
      newObj.new_number = item.new.number;
      newObj.new_text = item.new.text;
      // item
      newObj.service_number = item.service.number;
      newObj.service_text = item.service.text;
      // item
      newObj.matching_number = item.matching.number;
      newObj.matching_text = item.matching.text;
      // item
      newObj.pricing_number = item.pricing.number;
      newObj.pricing_text = item.pricing.text;
      // item
      newObj.total_rate_number = item.total_rate.number;
      newObj.total_rate_text = item.total_rate.text;

      // item
      newObj.user_name = item.user.name;
      newObj.user_phone = item.user.phone;

      // item
      newObj.user_comment = item.user_comment;

      // item
      newObj.reply_date = item.reply ? item.reply.date : "";
      newObj.reply_owner_name = item.reply ? item.reply.owner.name : "";
      newObj.reply_owner_phone = item.reply ? item.reply.owner.phone : "";

      newObj.reply_owner_comment = item.reply ? item.reply.owner_comment : "";

      newObj.status = item.status;

      newarr.push(newObj);
    });
    setdata(newarr);
  }, [dataFirst]);

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`get-users-rates?page=${pageNumber}`).then((res) => {
      setdataFirst(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                x: 1650,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersRates;
