import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Form,
  DatePicker,
} from "antd";
import { get, postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const AddEmployee = () => {
  const navigate = useNavigate();
  const [inputsValue, setinputsValue] = useState({});
  const [dataNationality, setDataNationality] = useState([]);
  const [optionNationality, setOptionNationality] = useState([]);

  useEffect(() => {
    setOptionNationality(
      dataNationality.map((d) => ({
        value: d.id,
        label: d.nationality_ar,
      }))
    );
  }, [dataNationality]);

  const [dataResidence_type, setDataResidence_type] = useState([]);
  const [optionResidence_type, setOptionResidence_type] = useState([]);
  useEffect(() => {
    setOptionResidence_type(
      dataResidence_type.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataResidence_type]);

  const [dataQualification, setDataQualification] = useState([]);
  const [optionQualification, setOptionQualification] = useState([]);
  useEffect(() => {
    setOptionQualification(
      dataQualification.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataQualification]);

  const [dataPay_type, setDataPay_type] = useState([]);
  const [optionPay_type, setOptionPay_type] = useState([]);
  useEffect(() => {
    setOptionPay_type(
      dataPay_type.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataPay_type]);

  const [dataRole_id, setDataRole_id] = useState([]);
  const [optionRole_id, setOptionRole_id] = useState([]);
  useEffect(() => {
    setOptionRole_id(
      dataRole_id.map((d) => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataRole_id]);

  useEffect(() => {
    get("get-all-nationalities").then((res) => {
      setDataNationality(res.data);
    });
    get("get-all-residence-types").then((res) => {
      setDataResidence_type(res.data);
    });
    get("get-all-qualifications").then((res) => {
      setDataQualification(res.data);
    });
    get("get-all-pay-types").then((res) => {
      setDataPay_type(res.data);
    });
    get("get-all-roles").then((res) => {
      setDataRole_id(res.data.data);
    });
  }, []);

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setinputsValue({ ...inputsValue, city_id: value });
  };
  const onSearch = (value) => {};

  const handleChange = (e) => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const [selected, setSelected] = useState([]);
  const blockedProperties = (items) => {
    setSelected(items);
    setinputsValue({ ...inputsValue, added_properties: items });
  };

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const processedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value ?? ""])
    );
    postFromData("employees", {
      ...processedValues,
      contact_end_date: values.contact_end_date.format("YYYY-MM-DD"),
      contact_start_date: values.contact_start_date.format("YYYY-MM-DD"),
      graduation_date: values.graduation_date.format("YYYY-MM-DD"),
      dob: values.dob.format("YYYY-MM-DD"),
    })
      .then((res) => {
        message.success("تم الإضافة بنجاح");
        setLoading(false);
        navigate("/employees");
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        إضافة موظف جديدة{" "}
      </Title>
      <Form
        onFinish={onFinish}
        labelCol={{
          span: 9,
        }}
        wrapperCol={{
          span: 15,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <div className="row">
          <div className="col-lg-4">
            <Form.Item
                label="الاسم"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="البريد الإلكتروني"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="الجوال"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input type="number"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="العنوان"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="الجنس"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد النوع"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "ذكر",
                    },
                    {
                      value: "2",
                      label: "مؤنث",
                    },
                  ]}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="الحالة الاجتماعية"
                name="marital_status"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الحالة الاجتماعية"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "اعزب",
                    },
                    {
                      value: "2",
                      label: "متزوج",
                    },
                  ]}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="التقدير "
                name="rate"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد  التقدير"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 0,
                      label: " غير مقيم ",
                    },
                    {
                      value: 1,
                      label: "ضعيف",
                    },
                    {
                      value: 2,
                      label: "مقبول",
                    },
                    {
                      value: 3,
                      label: "جيد",
                    },
                    {
                      value: 4,
                      label: " جيدجداً",
                    },
                    {
                      value: 5,
                      label: "   ممتاز",
                    },
                  ]}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="الجنسية"
                name="nationality_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الجنسية"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionNationality}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="دولة المؤهل"
                name="country_of_certificate"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد دولة المؤهل"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionNationality}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="نوع الإقامة"
                name="residence_type"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد نوع الإقامة"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionResidence_type}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="المؤهل"
                name="qualification"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المؤهل"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionQualification}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="الأذونات"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الأذونات  "
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionRole_id}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="رقم الاثبات"
                name="id_number"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input type="number"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="المسمى وظيفي"
                name="job_title"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="التأمينات الاجتماعية"
                name="social_insurance"
                // rules={[
                //   {
                //     required: true,
                //     message: "هذا الحقل مطلوب!",
                //   },
                // ]}
            >
              <Input type="number"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="مصدر التوظيف  "
                name="employment_source"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="تاريخ الميلاد"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="الراتب"
                name="salary"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input type="number"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="   خصم التأمينات  "
                name="insurance_discount"
                // rules={[
                //   {
                //     required: true,
                //     message: "هذا الحقل مطلوب!",
                //   },
                // ]}
            >
              <Input type="number"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="التخصص"
                name="specialization"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="مصدر الشهادة"
                name="source_of_certificate"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="تاريخ التخرج"
                name="graduation_date"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="   تاريخ التعاقد  "
                name="contact_start_date"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="  تاريخ انتهاء التعاقد  "
                name="contact_end_date"
                // rules={[
                //   {
                //     required: true,
                //     message: "هذا الحقل مطلوب!",
                //   },
                // ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item label="الانستغرام" name="instagram_url">
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item label="تويتر" name="twitter_url">
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item label="فيسبوك" name="facebook_url">
              <Input/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item label="كلمة السر" name="password">
              <Input.Password/>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "جاري الإضافة..." : "إضافة"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddEmployee;
