import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";

const OrderDropdown = ({ order, allOrders, onOrderSelect }) => {
  const [selectedOrder, setSelectedOrder] = useState(order || "-");

  const handleMenuClick = (e) => {
    setSelectedOrder(e.key);
    onOrderSelect(e.key); // Callback if you need to perform an action on selection
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {allOrders.map((orderItem) => (
        <Menu.Item key={orderItem}>{orderItem}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button>{selectedOrder}</Button>
    </Dropdown>
  );
};

export default OrderDropdown;
