import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { get, patch } from "../../api/axios";
import { Pagination } from "antd";
import SearchBar from "../../utils/SearchBar";
const Payouts = () => {
  // render: (role) => (role == null ? "" : role.name),
  const columns = [
    {
      title: "     رقم الحجز       ",
      render: ({ invoice_id }) => invoice_id || "غير متوفر",
    },
    {
      title: "    رقم الحوالة    ",
      render: ({ id }) => (`TRN-${id}` || "غير متوفر"),
    },
    {
      title: " حالة الحوالة",
      render: ({ payout_status }) => payout_status || "غير متوفر",
    },

    {
      title: (
        <>
          تاريخ انشاء الحوالة
          <br />
           انشاء الحوالة بواسطة
          <br />
          تاريخ الحوالة
          <br />
          مبلغ الحوالة
        </>
      ),
      render: ({ paid_at, created_at, created_by, amount }) => (
        <span>
          {created_at || "-"}
          <br />
          {created_by || "-"}
          <br />
            {paid_at || "-"}
            <br/>
            {amount || " -"}
        </span>
      ),
    },
    {
      title: (
        <>
          طريقة استلام المبالغ <br />
          اسم صاحب الحساب <br />
          رقم جوال المحفظة
          <br />
          البنك <br />
          رقم الايبان
          <br />
          رقم الموظف
        </>
      ),
      render: ({ payment_method, owner_name, wallet, bank, paid_by }) => (
        <span>
          {payment_method?.label || "غير متوفر"}
          <br />
          {owner_name || "غير متوفر"}
          <br />
          {payment_method.type === "bank_account" ? (
            <>
              {bank?.name_ar} <br />
              {bank?.iban || "غير متوفر"}
              <br />
            </>
          ) : (
            <>
              {wallet || "غير متوفر"}
              <br />
            </>
          )}
          <br /> {paid_by}
        </span>
      ),
    },
    {
      title: " الحالة",
      dataIndex: "payout_status",
    },

    {
      title: "تغير الحالة",
      render: ({ payout_status, id }) => (
        <>
          <button
            onClick={() => handleStatus(id)}
            disabled={payout_status === "منفذة" || loadingStates[id]}
            className={`btn ${
              payout_status === "منفذة" ? "btn-primary" : "btn-warning"
            }  py-0`}
          >
            {loadingStates[id]
              ? "Loading..."
              : payout_status === "منفذة"
              ? "منفذة"
              : "قيد التنفيذ"}
          </button>
        </>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);
  const [total, setTotal] = useState(1);
  const [loadingStates, setLoadingStates] = useState({});
  const handleStatus = (id) => {
      setLoadingStates((prev) => ({ ...prev, [id]: true })); // Set loading for the specific button
    patch(`payouts/${id}`, {}, {})
        .then((res) => {
            message.success("تم تغيير الحالة بنجاح").then(r => {
                setLoading(true);
                //reload the data
                get(`payouts?search=${searchQuery}`).then((res) => {
                    setdata(res?.data.data);
                    setTotal(res?.data.meta.last_page);
                    setLoading(false);
                });
            });
        })
        .catch((error) => {
            message.error("حدث خطأ أثناء تغيير الحالة");
        })
        .finally(() => {
            setLoadingStates((prev) => ({ ...prev, [id]: false })); // Reset loading for the specific button
        });
  };

  useEffect(() => {
    get(`payouts?search=${searchQuery}`).then((res) => {
      setdata(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);
  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`payouts?page=${pageNumber}`).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                //     x: 1350,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;
