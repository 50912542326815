import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  message,
  Spin,
  Form,
  Descriptions,
  Divider,
  Input,
  Switch,
} from "antd";
import { get, newPostFromData, postFromData, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";

const { Title } = Typography;

const PayoutCreate = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [fromLoading, setFromLoading] = useState(false);

  const [inputsValue, setInputsValue] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    get(`payout-create/${id}`).then((res) => {
      setInputsValue({
        amount: res?.data?.data?.amount,
        payout_status: 1,
        invoice_id: res?.data?.data?.invoice_id,
      });

      setUserData(res?.data?.data);
      setFromLoading(true);
    });
  }, []);

  const handleSwitch = (status) => {
    setInputsValue({ ...inputsValue, payout_status: status ? 1 : 0 });
  };

  const handleChange = (e) => {
    setInputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    newPostFromData(`payouts`, inputsValue)
      .then((res) => {
        message.success("تم إضافة الحوالة بنجاح");
        setLoading(false);
        navigate("/reservations");
      })
      .catch((err) => {
        message.error(err?.response?.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        إضافة حوالة{" "}
      </Title>

      {fromLoading ? (
        <>
          <Descriptions bordered colon>
            <Descriptions.Item label="نوع الأموال المستلمة">
              {userData?.money_recieve_type}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          {userData?.wallet_phone != null && (
            <Descriptions bordered colon>
              <Descriptions.Item label="رقم جوال المحفطة">
                {userData?.wallet_phone ?? ""}
              </Descriptions.Item>
            </Descriptions>
          )}

          {userData?.bank_id != null && (
            <>
              <Descriptions colon>
                <Descriptions.Item label="اسم البنك">
                  {userData?.bank_name ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="اسم صاحب الاحساب">
                  {userData?.bank_account_name ?? ""}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions colon>
                <Descriptions.Item label="رقم  iban">
                  {userData?.bank_account_iban ?? ""}
                </Descriptions.Item>
                <Descriptions.Item label="رقم  حساب البنك">
                  {userData?.back_account_id ?? ""}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}

          <Divider />

          <Form
            className="wrapper-content"
            onFinish={onFinish}
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 15,
            }}
            initialValues={{
              amount: inputsValue.amount,
            }}
          >
            <div className="row">
              <div className="col-lg-12">
                <Form.Item onChange={handleChange} label="المبلغ" name="amount">
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-12">
                <Form.Item name="payout_status" label="الحالة">
                  <Switch onChange={handleSwitch} defaultChecked></Switch>
                </Form.Item>
              </div>

              <div className="col-lg-4 offset-lg-4">
                <Form.Item
                  wrapperCol={{
                    offset: 1,
                    span: 16,
                  }}
                >
                  <Button
                    loading={loading}
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                  >
                    {loading ? "جاري الإضافة..." : "إضافة"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default PayoutCreate;
