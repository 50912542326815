import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { get, patch } from "../../api/axios";
import { Pagination } from "antd";
import SearchBar from "../../utils/SearchBar";

const Refunds = () => {
  // render: (role) => (role == null ? "" : role.name),
  const columns = [
    {
      title: "  رقم الاسترداد",
      dataIndex: "id",
    },
    {
      title: "  تاريخ  انشاء الحجز وقت الحجز",
      width: "120px",
      render: ({ created_at }) => <span>{created_at}</span>,
    },

    {
      title: " بيانات السعر والدفع ",
      render: ({ invoice, method }) => (
        <span>
          اجمالي السعر :<br />
          {invoice?.prices?.totalPrice || "غير متوفر"}
          <br />
          العرض :<br />
          {invoice?.prices?.offers_amount || "غير متوفر"}
          <br />
          كود خصم: <br />
          {invoice?.prices?.coupon_amount || "غير متوفر"}
          <br />
          مبلغ الحجز:
          <br />
          {invoice?.prices?.subTotal || "غير متوفر"}
          <br />
          رسوم الخدمة:
          <br />
          {invoice?.prices?.commission_amount || "غير متوفر"}
          <br />
          المجموع:
          <br />
          {invoice?.prices?.ownerTotalPayout || "غير متوفر"}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات الوحدة ",
      dataIndex: "property",
      render: ({ name, code }) => (
        <span>
          الوحدة:
          <br />
          {name || "غير متوفر"}
          <br />
          كود الوحدة:
          <br />
          {code || "غير متوفر"}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات المضيف ",
      dataIndex: "owner",
      render: ({ name, phone, email }) => (
        <span>
          الاسم:
          <br />
          {name || "-"}
          <br />
          الجوال:
          <br />
          {phone || "-"}
          <br />
          البريد الإلكتروني:
          <br />
          {email || "-"}
        </span>
      ),
    },
    {
      title: "   بيانات الضيف  ",
      dataIndex: "user",
      render: ({ name, phone, email }) => (
        <span>
          الاسم: <br />
          {name || "-"}
          <br />
          الجوال: <br />
          {phone || "-"}
          <br />
          البريد الإلكتروني: <br />
          {email || "-"}
        </span>
      ),
    },

    {
      title: (
        <>
          تاريخ الاسترداد
          <br />
          مبلغ الاسترداد
          <br />
          نوع الاسترداد
          <br />
          
          الموظف
        </>
      ),
      // dataIndex: "refund_data",
      render: ({ refunded_at, amount, method, refunded_by }) => (
        <span>
          {refunded_at || "-"}

          <br />
          {amount || "-"}
          <br />
          {method || "-"}
          <br />
          {refunded_by || "-"}
        </span>
      ),
    },
    {
      title: " سبب الالغاء",
      dataIndex: "comment",
    },
    {
      title: " الحالة",
      dataIndex: "status",
    },

    {
      title: "تغير الحالة",
      render: ({ status, id }) => (
        <>
          <button
            onClick={() => handleStatus(id)}
            disabled={status === "منفذ" || loadingStates[id]}
            className={`btn ${
              status === "منفذ" ? "btn-primary" : "btn-warning"
            }  py-0`}
          >
            {loadingStates[id]
              ? "Loading..."
              : status === "منفذ"
              ? "منفذ"
              : "قيد التنفيذ"}
          </button>
        </>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const [total, setTotal] = useState(1);

  const [loadingStates, setLoadingStates] = useState({});
  const handleStatus = (id) => {
    setLoadingStates((prev) => ({ ...prev, [id]: true })); // Set loading for the specific button
    patch(`refunds/${id}`, {}, {})
      .then((res) => {
        message.success("تم تغيير الحالة بنجاح");
        setdata(res?.data.data);
      })
      .catch((error) => {
        message.error("حدث خطأ أثناء تغيير الحالة");
      })
      .finally(() => {
        setLoadingStates((prev) => ({ ...prev, [id]: false }));  
      });
  };
  useEffect(() => {
    get(`refunds?search=${searchQuery}`).then((res) => {
      setdata(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`refunds?page=${pageNumber}`).then((res) => {
      setdata(res?.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                y: "65vh",
                x: 1750,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refunds;
