import React, {useEffect, useState} from 'react'
import {Button, Form, Input, message, Select, Spin} from 'antd';
import {useNavigate} from 'react-router-dom';
import {get, postFromData} from '../../../../api/axios';
import Steper from '../layoutProperty/Steper';


const Step1 = () => {
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState(
        {
            "code": '',
            "commercial_record": '',
            "commercial_record_name": "",
            "owner_id": "",
        }
    );
    const [dataRegions, setDataRegions] = useState([]);
    const [option, setOption] = useState([]);

    useEffect(() => {
        setOption(dataRegions.map(d => ({
            "value": d.id,
            "label": d.name,
        })))

    }, [dataRegions]);

    useEffect(() => {
        get('get-all-owners').then(res => {
            setDataRegions(res.data);
        });
    }, []);

    const onChange = (value) => {
        setinputsValue({ ...inputsValue, owner_id: value })
    };

    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })

    };

    const [loading, setLoading] = useState(false);
    const [fromloading, setFromLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        postFromData('add-property/step-1', values).then(res => {
            // message.success('تم الإضافة بنجاح');
            setLoading(false);
            localStorage.setItem('codeAddProperty', res.data.code);


            navigate('/add-property/step-2')
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        });

    };

    return (
        <div>
            <Steper current={1} />
            {!fromloading ?

                <Form
                    onFinish={onFinish}
                    labelCol={{
                        span: 10,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    initialValues={{
                        commercial_record: inputsValue.commercial_record,
                        commercial_record_name: inputsValue.commercial_record_name,
                        owner_id: inputsValue.owner_id,
                    }}
                    autoComplete="off"
                >
                    <div className='row'>
                        <div className='col-lg-3'>
                        <Form.Item
                          label="السجل التجاري"
                          name="commercial_record"
                        >
                          <Input type="number" />
                        </Form.Item>
                        </div>
                        <div className='col-lg-5'>

                            <Form.Item
                                label='اسم المنشاة فى السجل التجارى'
                                name='commercial_record_name'
                                onChange={handleChange}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Item
                                label='المالك'
                                name='owner_id'
                                onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: 'هذا الحقل مطلوب!',
                                    },
                                ]}
                            >
                                <Select
                                    className='w-100'
                                    showSearch
                                    placeholder="حدد المالك"
                                    optionFilterProp="children"
                                    onChange={onChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={option}
                                />

                            </Form.Item>
                        </div>

                        <div className='col-lg-4 offset-lg-4 mt-4'>

                            <Form.Item
                                wrapperCol={{
                                    offset: 1,
                                    span: 16,
                                }}
                            >
                                <Button
                                    loading={loading}
                                    size='large' block type="primary" htmlType="submit">
                                    {loading ? 'جاري ...' : 'التالي'}
                                </Button>
                            </Form.Item>
                        </div>
                    </div>



                </Form>

                :

                <div className='text-center'>
                    <Spin size="large" />
                </div>
            }
        </div>
    )
}

export default Step1
