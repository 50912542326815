import React, { useEffect, useState } from "react";
import { Divider, Spin } from "antd";
import { get } from "../../api/axios";
import StatisticItem from "./StatisticItem";

const MakanStatistics = () => {
  const [data, setData] = useState(null);
  const [rating, setRating] = useState(null);
  const [views, setViews] = useState(null);
  const [sales, setSales] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllStatistics = async () => {
      try {
        const [dataResponse, viewsResponse, ratingResponse, salesResponse] =
          await Promise.all([
            get("get-makan-statistics"),
            get("/statistics/views"),
            get("/statistics/rating"),
            get("/statistics/sales"),
          ]);

        setData(dataResponse?.data);
        setViews(viewsResponse?.data);
        setRating(ratingResponse?.data);
        setSales(salesResponse?.data);
      } catch (err) {
        console.error("Error fetching statistics:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllStatistics();
  }, []);
  if (loading) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }
  if (!data) {
    return <div className="text-center">No data available.</div>;
  }

  return (
    <div className="makan-statistics">
      <h3 className="text-main">أحصائيات مكان</h3>

      <Divider orientation="left">العقارات</Divider>
      <div className="row mt-2">
        {data?.properties?.map((item, index) => (
          <StatisticItem key={index} label={item.name} value={item.count} />
        ))}
      </div>

      <Divider orientation="left">المستخدمين</Divider>
      <div className="row mt-2">
        <StatisticItem label="عدد المستخدمين" value={data.users?.all?.all} />
        <StatisticItem label="عدد الضيوف" value={data.users?.guests?.all} />
        <StatisticItem label="عدد المضيفين" value={data.users?.owners?.all} />
        <StatisticItem
          label="عدد المستخدمين (Web)"
          value={data.users?.all?.web}
        />
        <StatisticItem
          label="عدد الضيوف (Web)"
          value={data.users?.guests?.web}
        />
        <StatisticItem
          label="عدد المضيفيين (Web)"
          value={data.users?.owners?.web}
        />
        <StatisticItem
          label="عدد المستخدمين (iOS)"
          value={data.users?.all?.ios}
        />
        <StatisticItem
          label="عدد الضيوف (iOS)"
          value={data.users?.guests?.ios}
        />
        <StatisticItem
          label="عدد المضيفيين (iOS)"
          value={data.users?.owners?.ios}
        />
        <StatisticItem
          label="عدد المستخدمين (Android)"
          value={data.users?.all?.android}
        />
        <StatisticItem
          label="عدد الضيوف (Android)"
          value={data.users?.guests?.android}
        />
        <StatisticItem
          label="عدد المضيفيين (Android)"
          value={data.users?.owners?.android}
        />
      </div>

      <Divider orientation="left">الحجوزات</Divider>
      <div className="row mt-2">
        <StatisticItem label="عدد الحجوزات" value={data.reservations?.total} />
        <StatisticItem
          label="عدد الحجوزات المؤكدة"
          value={data.reservations?.status?.confirmed}
        />
        <StatisticItem
          label="عدد الحجوزات الملغية"
          value={data.reservations?.status?.canceled}
        />
        <StatisticItem
          label="عدد الحجوزات (Web)"
          value={data.reservations?.platforms?.web}
        />
        <StatisticItem
          label="عدد الحجوزات (iOS)"
          value={data.reservations?.platforms?.ios}
        />
        <StatisticItem
          label="عدد الحجوزات (Android)"
          value={data.reservations?.platforms?.android}
        />
        <StatisticItem
          label="عدد الحجوزات (مكان)"
          value={data.reservations?.source?.user}
        />
        <StatisticItem
          label="عدد الحجوزات (المضيف)"
          value={data.reservations?.source?.owner}
        />
      </div>

      <Divider orientation="left">التقييمات</Divider>
      <div className="row mt-2">
        <StatisticItem
          label="نظافة المكان"
          value={Math.round(rating?.reply_clean)}
        />
        <StatisticItem label="عدد التقييمات  " value={rating.rate_count} />
        <StatisticItem label="حالة المكان  " value={rating.rate_new} />
        <StatisticItem
          label="عدد تعليقات الضيوف "
          value={rating.reply_note_count}
        />

        <StatisticItem label="خدمة المضيف" value={rating.rate_service} />
        <StatisticItem
          label="عدد ردود المضيفين على التعليقات "
          value={rating.rate_note_count}
        />

        <StatisticItem label="تطابق المواصفات" value={rating.rate_matching} />
        <StatisticItem
          label="معدل عدد ردود المضيفين لعدد التعليقات "
          value={Math.floor(rating.reply_total_rating)}
        />

        <StatisticItem label="الجودة مقابل السعر" value={rating.rate_pricing} />
        <StatisticItem
          label="معدل عدد التقييمات لعدد الحجوزات"
          value={rating.reply_count}
        />
        <StatisticItem label="التقييم العام" value={rating.rate_total_rating} />
        {/* <StatisticItem
          label="معدل عدد التعليقات لعدد الحجوزات "
          value={rating.reservations_count}
        /> */}
      </div>

      <Divider orientation="left">المشاهدات</Divider>
      <div className="row mt-2">
        <StatisticItem label="عدد النقرات " value={views.total_clicks} />
        <StatisticItem label="عدد المشاهدات " value={views.users_views} />
        <StatisticItem label="عدد الزيارات " value={views.guests_views} />
        <StatisticItem
          label="معدل تحول المشاهدات إلى نقرات "
          value={views.view_to_click_rate}
        />
        <StatisticItem
          label="معدل عدد تحول النقرات إلى حجز مؤكد "
          value={Math.round(views.click_to_reservation_rate)}
        />
      </div>

      <Divider orientation="left">المبيعات</Divider>
      <br />
      <h6>المبيعات الكلية </h6>
      <div className="row mt-2">
        <StatisticItem
          label="المجموع المدفوع من الضيوف"
          value={Math.floor(sales.paid?.total_price || 0)}
        />
        <StatisticItem
          label="صافي مبيعات مكان (الرسوم)"
          value={Math.floor(sales.paid?.service_amount || 0)}
        />
        <StatisticItem
          label="ضريبة القيمة المضافة (الرسوم)"
          value={Math.floor(sales.paid?.service_tax || 0)}
        />
        <StatisticItem
          label="(الرسوم شامل الضريبة)"
          value={Math.floor(sales.paid?.total_service || 0)}
        />
        <StatisticItem
          label="مبيعات المضيفين"
          value={Math.floor(sales.paid?.owner_total_payout || 0)}
        />
        <StatisticItem
          label="صافي مبيعات مكان (العمولة)"
          value={Math.floor(sales.paid?.commission_amount || 0)}
        />
        <StatisticItem
          label="ضريبة القيمة المضافة (العمولة)"
          value={Math.floor(sales.paid?.service_tax || 0)}
        />
        <StatisticItem
          label="(العمولة شامل الضريبة)"
          value={Math.floor(sales.paid?.total_commission || 0)}
        />
      </div>

      <h6> المبيعات المستردة </h6>
      <div className="row mt-2">
        <StatisticItem
          label="المجموع المدفوع من الضيوف"
          value={Math.floor(sales.canceled?.total_price || 0)}
        />
        <StatisticItem
          label="صافي مبيعات مكان (الرسوم)"
          value={Math.floor(sales.canceled?.service_amount || 0)}
        />
        <StatisticItem
          label="ضريبة القيمة المضافة (الرسوم)"
          value={Math.floor(sales.canceled?.service_tax || 0)}
        />
        <StatisticItem
          label="(الرسوم شامل الضريبة)"
          value={Math.floor(sales.canceled?.total_service || 0)}
        />
        <StatisticItem
          label="مبيعات المضيفين"
          value={Math.floor(sales.canceled?.owner_total_payout || 0)}
        />
        <StatisticItem
          label="صافي مبيعات مكان (العمولة)"
          value={Math.floor(sales.canceled?.commission_amount || 0)}
        />
        <StatisticItem
          label="ضريبة القيمة المضافة (العمولة)"
          value={Math.floor(sales.canceled?.service_tax || 0)}
        />
        <StatisticItem
          label="(العمولة شامل الضريبة)"
          value={Math.floor(sales.canceled?.total_commission || 0)}
        />
      </div>

      <h6>صافي المبيعات </h6>
      <div className="row mt-2">
        <StatisticItem
          label="المجموع المدفوع من الضيوف"
          value={Math.floor(sales.total?.total_price || 0)}
        />
        <StatisticItem
          label="صافي مبيعات مكان (الرسوم)"
          value={Math.floor(sales.total?.service_amount || 0)}
        />
        <StatisticItem
          label="ضريبة القيمة المضافة (الرسوم)"
          value={Math.floor(sales.total?.service_tax || 0)}
        />
        <StatisticItem
          label="(الرسوم شامل الضريبة)"
          value={Math.floor(sales.total?.total_service || 0)}
        />
        <StatisticItem
          label="مبيعات المضيفين"
          value={Math.floor(sales.total?.owner_total_payout || 0)}
        />
        <StatisticItem
          label="صافي مبيعات مكان (العمولة)"
          value={Math.floor(sales.total?.commission_amount || 0)}
        />
        <StatisticItem
          label="ضريبة القيمة المضافة (العمولة)"
          value={Math.floor(sales.total?.service_tax || 0)}
        />
        <StatisticItem
          label="(العمولة شامل الضريبة)"
          value={Math.floor(sales.total?.total_commission || 0)}
        />
      </div>
    </div>
  );
};

export default MakanStatistics;

// import React, { useEffect, useState } from "react";
// import { Divider, Spin } from "antd";
// import { get } from "../../api/axios";
// import { get } from "../../api/axios";
// const MakanStatistics = () => {
//   const [data, setData] = useState({});
//   const [dataSales, setDataSales] = useState({});

//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     get("get-makan-statistics").then((res) => {
//       setData(res?.data);
//       setLoading(false);
//     });
//   }, []);
//   useEffect(() => {
//     get("/statistics/sales").then((res) => {
//       setDataSales(res?.data);
//     });
//   }, []);

//   useEffect(() => {
//     get("/statistics/views").then((res) => {
//       console.lg(res?.data);
//     });
//   }, []);
//   // useEffect(() => {
//   //   get("/statistics/views").then((res) => {
//   //     console.lg(res?.data);
//   //   });
//   // }, []);
//   console.log(dataSales);
//   return (
//     <div className="makan-statistics">
//       <h3 className="text-main">أحصائيات مكان</h3>

//       {loading ? (
//         <div className="text-center">
//           <Spin size="large" />
//         </div>
//       ) : (
//         <>
//           {/* Properties Section */}
//           <Divider orientation="left">العقارات</Divider>
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>الوصف</th>
//                 <th>العدد</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.properties?.map((item) => (
//                 <tr key={item.name}>
//                   <td>{item.name}</td>
//                   <td>{item.count}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>

//           {/* Users Section */}
//           <Divider orientation="left">المستخدمين</Divider>
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>الوصف</th>
//                 <th>العدد</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>عدد المستخدمين</td>
//                 <td>{data.users?.all?.all}</td>
//               </tr>
//               <tr>
//                 <td>عدد الضيوف</td>
//                 <td>{data.users?.guests?.all}</td>
//               </tr>
//               <tr>
//                 <td>عدد المضيفين</td>
//                 <td>{data.users?.owners?.all}</td>
//               </tr>
//               <tr>
//                 <td>عدد المستخدمين (Web)</td>
//                 <td>{data.users?.all?.web}</td>
//               </tr>
//               <tr>
//                 <td>عدد الضيوف (Web)</td>
//                 <td>{data.users?.guests?.web}</td>
//               </tr>
//               <tr>
//                 <td>عدد المضيفين (Web)</td>
//                 <td>{data.users?.owners?.web}</td>
//               </tr>
//               <tr>
//                 <td>عدد المستخدمين (iOS)</td>
//                 <td>{data.users?.all?.ios}</td>
//               </tr>
//               <tr>
//                 <td>عدد الضيوف (iOS)</td>
//                 <td>{data.users?.guests?.ios}</td>
//               </tr>
//               <tr>
//                 <td>عدد المضيفين (Android)</td>
//                 <td>{data.users?.owners?.android}</td>
//               </tr>
//               <tr>
//                 <td>عدد المستخدمين (Android)</td>
//                 <td>{data.users?.all?.android}</td>
//               </tr>
//               <tr>
//                 <td>عدد الضيوف (Android)</td>
//                 <td>{data.users?.guests?.android}</td>
//               </tr>
//               <tr>
//                 <td>عدد المضيفين (iOS)</td>
//                 <td>{data.users?.owners?.ios}</td>
//               </tr>
//             </tbody>
//           </table>

//           {/* Reservations Section */}
//           <Divider orientation="left">الحجوزات</Divider>
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>الوصف</th>
//                 <th>العدد</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>عدد الحجوزات</td>
//                 <td>{data.reservations?.total}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات المؤكدة</td>
//                 <td>{data.reservations?.status?.confirmed}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات الملغية</td>
//                 <td>{data.reservations?.status?.canceled}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات (Web)</td>
//                 <td>{data.reservations?.platforms?.web}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات (iOS)</td>
//                 <td>{data.reservations?.platforms?.ios}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات (Android)</td>
//                 <td>{data.reservations?.platforms?.android}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات (مكان)</td>
//                 <td>{data.reservations?.source?.user}</td>
//               </tr>
//               <tr>
//                 <td>عدد الحجوزات (المضيف)</td>
//                 <td>{data.reservations?.source?.owner}</td>
//               </tr>
//             </tbody>
//           </table>

//           {/* Rating Section */}

//           <Divider orientation="left">التقييمات</Divider>
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>العنصر</th>
//                 <th>القيمة</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>نظافة المكان</td>
//                 <td>{data.reservations?.total || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد التقييمات</td>
//                 <td>{data.reservations?.status?.confirmed || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد المحادثات</td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>حالة المكان</td>
//                 <td>{data.reservations?.platforms?.web || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد تعليقات الضيوف</td>
//                 <td>{data.reservations?.platforms?.ios || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد الردود على المحادثات</td>
//                 <td>{data.reservations?.platforms?.android || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>خدمة المضيف</td>
//                 <td>{data.reservations?.source?.user || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد ردود المضيفين على التعليقات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>معدل عدد ردود المضيف على المحادثات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>تطابق المواصفات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>معدل عدد ردود المضيفين لعدد التعليقات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>معدل عدد المحادثات لعدد الحجوزات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>الجودة مقابل السعر</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>معدل عدد التقييمات لعدد الحجوزات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>التقييم العام</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>معدل عدد التعليقات لعدد الحجوزات</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//             </tbody>
//           </table>

//           {/* views Section */}
//           <Divider orientation="left">المشاهدات</Divider>
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>العنصر</th>
//                 <th>القيمة</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td> عدد النقرات </td>
//                 <td>{data.reservations?.total || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد المشاهدات</td>
//                 <td>{data.reservations?.status?.confirmed || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>عدد الزيارات</td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> معدل تحول المشاهدات إلى نقرات </td>
//                 <td>{data.reservations?.platforms?.web || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>معدل تحول النقرات إلى حجز مؤكد </td>
//                 <td>{data.reservations?.platforms?.ios || "غير متاح"}</td>
//               </tr>
//             </tbody>
//           </table>

//           <Divider orientation="left">المبيعات</Divider>
//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>المبيعات الكلية</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>المجموع المدفوع من الضيوف </td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> صافي مبيعات مكان ( الرسوم )</td>
//                 <td>{data.reservations?.total || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( الرسوم ) </td>
//                 <td>{data.reservations?.status?.confirmed || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> ( الرسوم شامل الضريبة ) </td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> مبيعات المضيفين</td>
//                 <td>{data.reservations?.platforms?.web || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات مكان ( العمولة ) </td>
//                 <td>{data.reservations?.platforms?.ios || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( العمولة ) </td>
//                 <td>{data.reservations?.platforms?.android || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>خدمة المضيف</td>
//                 <td>{data.reservations?.source?.user || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>( العمولة شامل الضريبة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات المضيفيين</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات مكان ( الرسوم والعمولة ) </td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( الرسوم والعمولة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>( الرسوم والعمولة شامل الضريبة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//             </tbody>
//           </table>

//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>المبيعات المستردة</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>المجموع المدفوع من الضيوف </td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> صافي مبيعات مكان ( الرسوم )</td>
//                 <td>{data.reservations?.total || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( الرسوم ) </td>
//                 <td>{data.reservations?.status?.confirmed || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> ( الرسوم شامل الضريبة ) </td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> مبيعات المضيفين</td>
//                 <td>{data.reservations?.platforms?.web || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات مكان ( العمولة ) </td>
//                 <td>{data.reservations?.platforms?.ios || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( العمولة ) </td>
//                 <td>{data.reservations?.platforms?.android || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>خدمة المضيف</td>
//                 <td>{data.reservations?.source?.user || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>( العمولة شامل الضريبة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات المضيفيين</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات مكان ( الرسوم والعمولة ) </td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( الرسوم والعمولة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>( الرسوم والعمولة شامل الضريبة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//             </tbody>
//           </table>

//           <table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>صافي المبيعات</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>المجموع المدفوع من الضيوف </td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> صافي مبيعات مكان ( الرسوم )</td>
//                 <td>{data.reservations?.total || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( الرسوم ) </td>
//                 <td>{data.reservations?.status?.confirmed || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> ( الرسوم شامل الضريبة ) </td>
//                 <td>{data.reservations?.status?.canceled || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td> مبيعات المضيفين</td>
//                 <td>{data.reservations?.platforms?.web || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات مكان ( العمولة ) </td>
//                 <td>{data.reservations?.platforms?.ios || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( العمولة ) </td>
//                 <td>{data.reservations?.platforms?.android || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>خدمة المضيف</td>
//                 <td>{data.reservations?.source?.user || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>( العمولة شامل الضريبة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات المضيفيين</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>صافي مبيعات مكان ( الرسوم والعمولة ) </td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>ضريبة القيمة المضافة ( الرسوم والعمولة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//               <tr>
//                 <td>( الرسوم والعمولة شامل الضريبة )</td>
//                 <td>{data.reservations?.source?.owner || "غير متاح"}</td>
//               </tr>
//             </tbody>
//           </table>
//         </>
//       )}
//     </div>
//   );
// };

// export default MakanStatistics;
