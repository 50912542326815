import { AiFillYoutube } from "react-icons/ai";
import { FaSnapchatGhost } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { del, get, post, put } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination, Switch } from "antd";
import {
  InstagramOutlined,
  TwitterOutlined,
  EnvironmentOutlined,
  PinterestFilled,
} from "@ant-design/icons";
import { checkPermission } from "../auth/ProtectedRoutes";
 
const UnitRegistrations = () => {
  const columns = [
    {
      title: " رقم التسجيل ",
      dataIndex: "id",
    },
    {
      title: "التاريخ  ",
      dataIndex: "created_at",
    },
    {
      title: (
        <>
          نوع الوحدة
          <br />
          أسم الوحدة
          <br />
          صاحب الوحدة
        </>
      ),
      render: ({ property_type }, { property_name, owner_name }) => (
        <>
          {property_type.name}
          <br />
          {property_name}
          <br />
          {owner_name}
        </>
      ),
    },
    {
      title: (
        <>
          عدد التقييمات
          <br />
          عدد الصور
          <br />
          منصة أخرى
        </>
      ),
      render: ({ images_count, other_platform, rating_count }) => (
        <>
          {rating_count}
          <br />
          {images_count || "_"}
          <br />
          {other_platform || "_"}
        </>
      ),
    },
    {
      title: <>رابط الموقع</>,
      render: ({ location_url }) => (
        <>
          {location_url || "_"}
          <br />
          <a
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={location_url}
          >
            <EnvironmentOutlined style={{ fontSize: "20px" }} />
          </a>
        </>
      ),
    },
    {
      title: (
        <>
          المنطقة
          <br />
          المدينة
          <br />
          الحي
        </>
      ),
      render: ({ region, city, district }) => (
        <>
          {region?.name}
          <br />
          {city?.name}
          <br />
          {district?.name}
        </>
      ),
    },
    {
      title: (
        <>
          الجوال (1)
          <br />
          الجوال (2)
          <br />
          وتساب
        </>
      ),
      render: ({
        first_phone_number,
        second_phone_number,
        whatsapp_number,
      }) => (
        <>
          {first_phone_number}
          <br />
          {second_phone_number}
          <br />
          {whatsapp_number}
        </>
      ),
    },
    {
      title: (
        <>
          أنستغرام
          <br />
          تويتر
          <br />
          بينترست
        </>
      ),
      render: ({ instagram_url, twitter_url, pinterest_url }) => (
        <>
          <a
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={instagram_url}
          >
            <InstagramOutlined style={{ fontSize: "20px" }} />
          </a>
          <a
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={twitter_url}
          >
            <TwitterOutlined style={{ fontSize: "20px" }} />
          </a>
          <a
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={pinterest_url}
          >
            <PinterestFilled style={{ fontSize: "20px" }} />
          </a>
        </>
      ),
    },
    {
      title: (
        <>
          رقم المسوق
          <br />
          حالة التسجيل
          <br />
          كود الوحدة
        </>
      ),
      render: ({ marketer_number,note ,property_code}) => (
        <>
          {marketer_number || "-"}
          <br />
          {note || "-"}
          <br />
          {property_code || "-"}
        </>
      ),
    },
    {
      title: (
        <>
          {/* طريقة التواصل
          <br /> */}
          المتابعة
        </>
      ),
      render: ({ action }) => (
        <>
          {/* {} */}
          {/* <br /> */}
          {action}
        </>
      ),
    },

    // #####
  ];

  if (
    checkPermission("update-marketer") ||
    checkPermission("delete-marketer")
  ) {
    columns.push(
      {
        title: "تعديل",
        dataIndex: "id",
        render: (id) => (
          <button className="btn btn-outline-warning btn-sm p-0">
            <Link
              className="color-inherit text-decoration-none py-1 px-2 d-block"
              to={`/unit-registrations/edit/${id}`}
            >
              تعديل
            </Link>
          </button>
        ),
      },
      {
        title: "حذف",
        dataIndex: "id",
        render: (id) => (
          <Popconfirm
            title="حذف العنصر"
            description="هل أنت متأكد من حذف هذه العنصر؟"
            onConfirm={() => confirm(id)}
            onCancel={cancel}
            okText="نعم"
            cancelText="لا"
          >
            <Button danger>حذف</Button>
          </Popconfirm>
        ),
      }
    );
  }

  const [data, setdata] = useState([]);

  const [loading, setLoading] = useState(true);
   

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`unit-registrations?page=1`).then((res) => {
      setdata(res.data.data);
      // setTotal(res.data.meta.total)
      setLoading(false);
    });
  }, []);
  const handleDelet = (id) => {
    del(`unit-registrations/${id}`);
    let dateFilter = data.filter((item) => item.id !== id);
    setdata(dateFilter);
  };

  const confirm = (id) => {
     handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = (e) => {
     message.error("تم الغاء الامر");
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`unit-registrations?page=${pageNumber}`).then((res) => {
      setdata(res?.data?.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-marketer") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
              className="text-decoration-none d-block h-100"
              to="/unit-registrations/add"
            >
              <Button type="primary" block>
                {" "}
                إضافة{" "}
              </Button>
            </Link>
          </div>
        )}
         
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitRegistrations;
