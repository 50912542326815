import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { get } from "../../api/axios";
import { Pagination } from "antd";
import SearchBar from "../../utils/SearchBar";

const Offers = () => {
  const columns = [
    {
      title: " الرقم   ",
      dataIndex: "patch_id",
    },
    {
      title: "  رقم الطلب  ",
      dataIndex: "offer_number",
    },
    {
      title: "  تاريخ الطلب ",
      dataIndex: "created_at",
    },

    {
      title: "   اسم الخصم   ",
      dataIndex: "name",
    },
    {
      title: " كود الوحدة  ",
      dataIndex: "property_code",
    },
    {
      title: "  تاريخ البداية ",
      dataIndex: "start_date",
    },
    {
      title: "  تاريخ النهاية ",
      dataIndex: "end_date",
    },

    {
      title: " اليوم  ",

      render: ({ prices }) => (
        <>
          {prices.map((item) => {
            const dayMap = {
              thu: "الخميس",
              fri: "الجمعة",
              sat: "السبت",
            };

            // Default to "وسط الاسبوع" if the day is not in the map
            const dayName = dayMap[item.day] || "وسط الاسبوع";

            return <div key={item.day}>{dayName}</div>;
          })}
        </>
      ),
    },
    {
      title: "السعر",
      children: [
        {
          title: "ليلة",
          render: ({ prices }) => (
            <>
              {prices.map((item, index) => (
                <div key={index}>{item.night}</div>
              ))}
            </>
          ),
        },

        {
          title: " فترة صباحية  ",
          render: ({ prices }) => (
            <>
              {prices.map((item, index) => (
                <div key={index}>{item.day_time}</div>
              ))}
            </>
          ),
        },
        {
          title: " فترة مسائية ",
          render: ({ prices }) => (
            <>
              {prices.map((item, index) => (
                <div key={index}>{item.night_time}</div>
              ))}
            </>
          ),
        },
      ],
    },
    {
      title: "  حالة الطلب ",
      render: ({ status }) => (
        <>
          <Tag color={`${status === "منتهي" ? "error" : "success"}`}>
            {status}
          </Tag>
        </>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`get-offers?search=${searchQuery}`).then((res) => {
      setdata(res?.data.data);
      setTotal(res?.data?.meta?.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`get-offers?page=${pageNumber}`).then((res) => {
      setdata(res?.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
              }}
              bordered
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
