import React, { useEffect, useState } from "react";
import { Table, Button, Input } from "antd";
import { del, get } from "../../api/axios";
import { Link } from "react-router-dom";
import { message } from "antd";
import { Pagination } from "antd";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import SearchBar from "../../utils/SearchBar";

const Guests = () => {
  const columns = [
    {
      title: "رقم الضيف ",
      dataIndex: "id",
      width: "100px",
    },
    {
      title: "تاريخ التسجيل ",
      width: "100px",

      render: ({ created_at }) => <> {created_at}</>,
    },
    {
      title: (
        <>
          الاسم
          <br />
          البريد الإلكتروني
          <br />
          الجوال
        </>
      ),
      width: "100px",
      render: ({ name, phone, email }) => (
        <>
          {name}
          <br />
          {email}
          <br />
          {phone}
        </>
      ),
    },
    {
      title: (
        <>
          العنوان
          <br />
          الجنس
          <br />
          تاريخ الميلاد
        </>
      ),
      width: "100px",
      render: ({ address, dob, gender }) => (
        <>
          {address}
          <br />
          {gender}
          <br />
          {dob}
        </>
      ),
    },
    {
      title: (
        <>
          الجنسية
          <br />
          نوع الإقامة
          <br />
          رقم الاثبات
        </>
      ),
      width: "150px",
      render: ({ nationality_id, residence_type, id_number }) => (
        <>
          {nationality_id}
          <br />
          {residence_type}
          <br />
          {id_number}
        </>
      ),
    },
    {
      title: (
        <>
          حجوزاتي المؤكدة
          <br />
          حجوزاتي الملغية
          <br />
          حجوزاتي الكل
        </>
      ),
      width: "150px",
      render: ({ reservations_count }) => (
        <>
          {reservations_count.confirmed}
          <br />
          {reservations_count.canceled}
          <br />
          {reservations_count.all}
        </>
      ),
    },
    // {
    //   title: (
    //     <>
    //       تقييم الضيف لكل الوحدات
    //       <br />
    //       تقييم المضيفين للضيف
    //       <br />
    //       الشكاوي ضد الضيف
    //     </>
    //   ),
    //   width: "200px",
    //   render: () => (
    //     <>
    //       <br />

    //       <br />
    //     </>
    //   ),
    // },
    {
      title: "حالة الحساب ",
      dataIndex: "status",
      width: "100px",
    },
    {
      width: "210px",
      title: (
        <>
          تاريخ الإيقاف \ الحظر
          <br />
          تاريخ نهاية الإيقاف
          <br />
          تاريخ التنشيط
        </>
      ),
      render: ({ log, created_at }) => (
        <>
          {log?.created_at ? <>{log.created_at} </> : "-"}
          <br />
          {log?.block_to ? <> {log.block_to} </> : "-"}
          <br />
          {created_at}
        </>
      ),
    },

    {
      title: " تعديل البريد الإلكتروني",
      dataIndex: "id",
      width: "150px",

      render: (id) => (
        <button className="btn btn-outline-warning btn-sm p-0">
          <Link
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            to={`/guests/edit/${id}`}
          >
            <EditOutlined />
            تعديل
          </Link>
        </button>
      ),
    },
    {
      title: "تغيير الحالة ",
      dataIndex: "id",
      width: "150px",

      render: (id) => (
        <button className="btn btn-outline-warning btn-sm p-0">
          <Link
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            to={`/guests/change-status/${id}`}
          >
            <EditOutlined />
            تعديل
          </Link>
        </button>
      ),
    },
    // {
    //   title: "حذف",
    //   dataIndex: "id",
    //   render: id => (
    //     <Popconfirm
    //       title="حذف العنصر"
    //       description="هل أنت متأكد من حذف هذه العنصر؟"
    //       onConfirm={() => confirm(id)}
    //       onCancel={cancel}
    //       okText="نعم"
    //       cancelText="لا"
    //     >
    //       <Button danger>حذف</Button>
    //     </Popconfirm>
    //   ),
    // },
  ];
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`guests?page=1&search=${searchQuery}`).then((res) => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  const handleDelet = (id) => {
    del(`guests/${id}`);
    let dateFilter = data.filter((item) => item.id !== id);
    setdata(dateFilter);
  };

  const confirm = (id) => {
    console.log(id);
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = (e) => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

   
  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`guests?page=${pageNumber}&search=${searchQuery}`).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-4 offset-lg-4 mb-3">
          <Button type="primary" block>
            <Link
              className="text-decoration-none d-block h-100"
              to="/guests/add"
            >
              {" "}
              إضافة{" "}
            </Link>
          </Button>
        </div>
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 1250,
              }}
               columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={"1"}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guests;
