import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Form,
  DatePicker,
  Spin,
} from "antd";
import { get, postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const { Title } = Typography;

const ChangeStatus = () => {
  const navigate = useNavigate();
  const { TextArea } = Input;
  let { guestId } = useParams();
  const [status, setStatus] = useState(null);
  const [inputsValue, setinputsValue] = useState({
    reason: "",
    user_id: "",
    status: "",
    block_to: "",
    property_of_block: "",
    blocked_properties: "",
  });

  useEffect(() => {
    setinputsValue({
      ...inputsValue,
      user_id: guestId,
    });
  }, []);

  const [fromloading, setFromLoading] = useState(false);
  // Fetch owner data and update the state after data retrieval

  useEffect(() => {
    get(`guests/${guestId}`).then((res) => {
      setinputsValue({
        ...res.data,
        user_id: guestId,
        status: res.data.status,
      });
      setFromLoading(true);
      setStatus(res.data.status);
    });
  }, [guestId]);

  const handelStatusUser = (value) => {
    setinputsValue({ ...inputsValue, status: value });
    setStatus(value);
  };

  const handleChange = (e) => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const handleBlockTo = (date, dateString) => {
    setinputsValue({ ...inputsValue, block_to: dateString });
  };

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    postFromData("change-user-status", inputsValue)
      .then((res) => {
        message.success("تم الإضافة بنجاح");
        setLoading(false);
        navigate("/guests");
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تغيير حالة الضيف{" "}
      </Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-4">
              <Form.Item
                label="الحالة"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
                name="status"
              >
                <Select
                  className="w-100"
                  placeholder={status}
                  onChange={handelStatusUser}
                  options={[
                    {
                      value: "1",
                      label: "مفعل",
                    },
                    // {
                    //   value: "2",
                    //   label: "موقوف",
                    // },
                    {
                      value: "3",
                      label: "محظور",
                    },
                  ]}
                />
              </Form.Item>
            </div>

            {status > 1 ? (
              <>
                {status == 2 || status == 3 ? (
                  <>
                    <div className="col-lg-4">
                      <Form.Item
                        label="تغير الحالة  "
                        rules={[
                          {
                            required: true,
                            message: "هذا الحقل مطلوب!",
                          },
                        ]}
                        name=""
                      >
                        <DatePicker
                          placeholder=""
                          className="w-100"
                          onChange={handleBlockTo}
                        />
                      </Form.Item>
                    </div>

                    {/* <div className="col-lg-4">
                    <Form.Item
                      label="العقار"
                      onChange={handleChange}
                      rules={[
                        {
                          required: true,
                          message: "هذا الحقل مطلوب!",
                        },
                      ]}
                      name="property_of_block"
                    >
                      <Input />
                    </Form.Item>
                  </div> */}
                  </>
                ) : null}

                {status == 3 ? (
                  <>
                    {/* <div className="col-lg-4">
                    <Form.Item
                      label="العقارات"
                      // onChange={handleChange}
                      name="blocked_properties"
                      rules={[
                        {
                          required: true,
                          message: "هذا الحقل مطلوب!",
                        },
                      ]}
                    >
                      <div>
                        <TagsInput
                          value={selected}
                          onChange={blockedProperties}
                          // name="blocked_properties"
                        />
                        <small>لاضافة عقار اضغظ Enter بعد الكتابة</small>
                      </div>
                    </Form.Item>
                  </div> */}

                    <div className="col-lg-4">
                      <Form.Item
                        label="السبب"
                        rules={[
                          {
                            required: true,
                            message: "هذا الحقل مطلوب!",
                          },
                        ]}
                        name="reason"
                      >
                        <TextArea
                          rows={1}
                          name="reason"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <div className="row">
            <div className="col-lg-4 mt-3 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default ChangeStatus;
