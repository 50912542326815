
// export default Step6;
import React, { useState, useEffect } from 'react';
import Steper from '../layoutProperty/Steper';
import { Button, Divider, Form, Input, Radio, Select, Spin, message } from 'antd';
import { get, postFromData } from '../../../../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import IntlTelInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const Step6 = () => {
    const [loading, setLoading] = useState(false);
    const [fromLoading, setFromLoading] = useState(false);
    const [inputsValue, setInputsValue] = useState({});
    const [recieve_type, setRecieveType] = useState('bank_account');
    const [dataBanks, setDataBanks] = useState([]);
    const [optionBanks, setOptionBanks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('codeAddProperty')) {
            postFromData('add-property/step-6', { code: localStorage.getItem('codeAddProperty'), ignore_update_level: true })
                .then(res => {
                    setInputsValue(res.data);
                    setFromLoading(true);
                });
        } else {
            navigate('/add-property/step-1');
        }
    }, []);

    useEffect(() => {
        get('banks').then(res => {
            setDataBanks(res.data.data);
        });
    }, []);

    useEffect(() => {
        setOptionBanks(dataBanks.map(d => ({
            value: d.id,
            label: d.name_ar,
        })));
    }, [dataBanks]);

    const onChangeBankAccount = (type) => {
        setRecieveType(type);
    };

   

    // const validateMobileNumber = (_, value) => {
    //     if (recieve_type !== 'wallet_account') return Promise.resolve();
    //     return value ? Promise.resolve() : Promise.reject(new Error('يرجى إدخال رقم صحيح.'));
    // };

    const onFinish = (values) => {
        setLoading(true);
    
        const valuesToSend = {
            code: localStorage.getItem('codeAddProperty'),
            ...values,
            ...(recieve_type === 'wallet_account' && inputsValue.wallet_phone ? { wallet_phone: inputsValue.wallet_phone } : {})
        };
    
        postFromData('add-property/step-6', valuesToSend)
            .then(() => {
                setLoading(false);
                navigate('/add-property/step-7');
            })
            .catch(err => {
                message.error(err.response?.data?.message || 'حدث خطأ غير متوقع');
                setLoading(false);
            });
    };
    

    return (
        <div>
            <div className='add-prop step-6'>
                <Steper current={6} />
                {fromLoading ? (
                    <Form
                        onFinish={onFinish}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{}}
                    >
                        <div className='row'>
                            <div className='col-lg-12'>
                                <Divider orientation="left" className='text-main'>
                                    <span className='text-main'>طريقة استخدام مبالغ الحجوزات</span>
                                </Divider>
                                <Form.Item
                                    label='طريقة الاستلام'
                                    name="money_recieve_type"
                                >
                                    <Radio.Group onChange={(e) => onChangeBankAccount(e.target.value)} value={recieve_type}>
                                        <Radio value='bank_account'>تحويل لحساب بنكي</Radio>
                                        <Radio value='wallet_account'>ايداع في محفظة Syc pay الالكترونية (موصى به)</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {recieve_type === 'bank_account' && (
                                    <div className='row mt-3'>
                                        <div className='col-lg-8'>
                                            <Form.Item
                                                label='البنك'
                                                name='bank_id'
                                                rules={[{ required: true, message: 'هذا الحقل مطلوب!' }]}
                                            >
                                                <Select
                                                    className='w-100'
                                                    showSearch
                                                    placeholder="اختر البنك"
                                                    optionFilterProp="children"
                                                    options={optionBanks}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label='اسم صاحب الحساب'
                                                name='bank_account_name'
                                                rules={[{ required: true, message: 'هذا الحقل مطلوب!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label=' رقم الايبان'
                                                name='bank_account_iban'
                                                rules={[
                                                    {
                                                        required: recieve_type != 'bank_account' ? false : true,
                                                        message: 'هذا الحقل مطلوب!',
                                                    },
                                                ]}
                                            >
                                                <div className='d-flex p-relative'>
                                                    <Input disabled={recieve_type == 'bank_account' ? false : true} className='w-100' type='number' />
                                                    <span className='badge-sa'>SA</span>
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                                label='رقم الحساب'
                                                name='back_account_id'
                                                rules={[{ required: true, message: 'هذا الحقل مطلوب!' }]}
                                            >
                                                <Input className='w-100' type='number' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                )}

                                {recieve_type === 'wallet_account' && (
                                    <div className='row mt-3'>
                                        <div className='col-lg-8'>
                                            <Form.Item
                                                label="رقم الجوال"
                                                name="wallet_phone"
                                                rules={[
                                                    { required: true, message: "يرجى إدخال رقم الجوال!" },
                                                     
                                                ]}
                                            >
                                                <IntlTelInput
    preferredCountries={['sa', 'eg', 'ae', 'kw']}
    containerClassName="intl-tel-input"
    inputClassName="form-control"
    value={inputsValue.wallet_phone || ''}
    onChange={(value) => setInputsValue({ ...inputsValue, wallet_phone: value })}
    separateDialCode
/>

                                            </Form.Item>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-4 offset-lg-1'>
                                <Button loading={loading} size='large' block type="primary" htmlType="submit">
                                    {loading ? 'جاري ...' : 'التالي'}
                                </Button>
                            </div>
                            <div className='col-lg-4 offset-lg-1'>
                                <Button size='large' block>
                                    <Link className='d-block text-decoration-none' to='/add-property/step-5'>رجوع</Link>
                                </Button>
                            </div>
                        </div>
                    </Form>
                ) : (
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Step6;





// import React, { useState, useEffect } from 'react';
// import Steper from '../layoutProperty/Steper';
// import { Button, Divider, Form, Input, InputNumber, Radio, Select, Spin, message } from 'antd';
// import { get, postFromData } from '../../../../api/axios';
// import { Link, useNavigate } from 'react-router-dom';

// const Step6 = () => {
//     const [loading, setLoading] = useState(false);
//     const [fromloading, setFromLoading] = useState(false);
//     const navigate = useNavigate();

//     const [inputsValue, setinputsValue] = useState({})

//     useEffect(() => {
//         // return () => {

//         if (localStorage.getItem('codeAddProperty')) {
//             postFromData('add-property/step-6', { code: localStorage.getItem('codeAddProperty'), ignore_update_level: true }).then(res => {
//                 setinputsValue(res.data)
//                 setFromLoading(true)



//             })
//         } else {
//             navigate('/add-property/step-1');
//         }
//         // }
//     }, []);

//     const onFinish = (values) => {
//         setLoading(true);

//         let valuesToSend = [];
//         Object.keys(values).forEach((item) => {
//             if (recieve_type === 'wallet_account') {
//                 switch (item) {
//                     case 'bank_id':
//                     case 'bank_account_name':
//                     case 'bank_account_iban':
//                     case 'back_account_id':
//                         break;
//                     default:
//                         valuesToSend = [
//                             ...valuesToSend,
//                             values[item]
//                         ];
//                         break;
//                 }
//             }
//             else {
//                 switch (item) {
//                     case 'wallet_phone':
//                         break;
//                     default:
//                         valuesToSend = [
//                             ...valuesToSend,
//                             values[item]
//                         ];
//                         break;
//                 }
//             }
//         });


//         console.log('valuesToSend:', valuesToSend);
//         postFromData('add-property/step-6', { 'code': localStorage.getItem('codeAddProperty'), ...valuesToSend }).then(res => {
//             setLoading(false);
//             navigate('/add-property/step-7');
//         }).catch(err => {
//             message.error(err.response.data.message);
//             setLoading(false);
//         })

//     };


//     const [recieve_type, setrecieve_type] = useState('bank_account');

//     const onChangebank_account = (e) => {
//         setrecieve_type(e);
//     };

     
//     const [dataBanks, setDataBanks] = useState([]);
//     const [optionBanks, setOptionBanks] = useState([]);

//     useEffect(() => {
//         setOptionBanks(dataBanks.map(d => ({
//             "value": d.id,
//             "label": d.name_ar,
//         })))

//     }, [dataBanks]);

//     useEffect(() => {
//         get('banks').then(res => {
//             setDataBanks(res.data.data)
//         })
//     }, []);



//     return (
//         <div>
//             <div className='add-prop step-6'>
//                 <Steper current={6} />

//                 {
//                     fromloading ?
//                         <Form
//                             onFinish={onFinish}
//                             labelCol={{
//                                 span: 8,
//                             }}
//                             wrapperCol={{
//                                 span: 16,
//                             }}

//                             initialValues={{}}
//                         >

//                             <div className='row'>
//                                 <div className='col-lg-12'>
//                                     <Divider orientation="left" className='text-main'> <span className='text-main'> طريقة استخدام مبالغ الحجوزات  </span> </Divider>

//                                     <Radio value='bank_account' onChange={() => onChangebank_account('bank_account')} checked={recieve_type === 'bank_account' ? true : false} >  تحويل لحساب بنكي </Radio>
//                                     <div className='row mt-3'>
//                                         <div className='col-lg-8'>
//                                             <Form.Item
//                                                 label=' البنك'
//                                                 name='bank_id'
//                                                 rules={[
//                                                     {
//                                                         required: recieve_type != 'bank_account' ? false : true,
//                                                         message: 'هذا الحقل مطلوب!',
//                                                     },
//                                                 ]}
//                                             >

//                                                 <Select
//                                                     disabled={recieve_type == 'bank_account' ? false : true}
//                                                     className='w-100'
//                                                     showSearch
//                                                     placeholder=" اختر البنك"
//                                                     optionFilterProp="children"
//                                                     filterOption={(input, option) =>
//                                                         (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
//                                                     }
//                                                     options={optionBanks}
//                                                     rules={[
//                                                         {
//                                                             required: recieve_type != 'bank_account' ? false : true,
//                                                             message: 'هذا الحقل مطلوب!',
//                                                         },
//                                                     ]}
//                                                 />
//                                             </Form.Item>
//                                             <Form.Item
//                                                 label=' اسم صاحب الحساب'
//                                                 name='bank_account_name'
//                                                 rules={[
//                                                     {
//                                                         required: recieve_type != 'bank_account' ? false : true,
//                                                         message: 'هذا الحقل مطلوب!',
//                                                     },
//                                                 ]}
//                                             >
//                                                 <Input disabled={recieve_type == 'bank_account' ? false : true} />
//                                             </Form.Item>
//                                             <Form.Item
//                                                 label=' رقم الايبان'
//                                                 name='bank_account_iban'
//                                                 rules={[
//                                                     {
//                                                         required: recieve_type != 'bank_account' ? false : true,
//                                                         message: 'هذا الحقل مطلوب!',
//                                                     },
//                                                 ]}
//                                             >
//                                                 <div className='d-flex p-relative'>
//                                                     <Input disabled={recieve_type == 'bank_account' ? false : true} className='w-100' type='number' />
//                                                     <span className='badge-sa'>SA</span>
//                                                 </div>
//                                             </Form.Item>
//                                             <Form.Item
//                                                 disabled={recieve_type == 'bank_account' ? false : true}
//                                                 label=' رقم الحساب'
//                                                 name='back_account_id'
//                                                 rules={[
//                                                     {
//                                                         required: recieve_type != 'bank_account' ? false : true,
//                                                         message: 'هذا الحقل مطلوب!',
//                                                     },
//                                                 ]}
//                                             >
//                                                 <Input disabled={recieve_type == 'bank_account' ? false : true} className='w-100' type='number' />
//                                             </Form.Item>
//                                         </div>

//                                     </div>
//                                     <div className='row'>
//                                         <div className='col-lg-8'>
//                                             <Divider />
//                                             <Radio value='wallet_account' onChange={() => onChangebank_account('wallet_account')} checked={recieve_type === 'wallet_account' ? true : false} >  ايداع في محفظة Syc pay الالكترونية (موصى به) </Radio>
//                                             <Form.Item
//                                                 className='mt-3'
//                                                 label=' رقم الجوال'
//                                                 name={recieve_type != 'bank_account' ? "wallet_phone" : null}
//                                                 rules={[
//                                                     {
//                                                         required: recieve_type == 'bank_account' ? false : true,
//                                                         message: 'هذا الحقل مطلوب!',
//                                                     },
//                                                 ]}
//                                             >
//                                                 <Input disabled={recieve_type != 'bank_account' ? false : true} className='w-100' type='number' />
//                                             </Form.Item>
//                                         </div>
//                                     </div>    

  
//                                 </div>



//                             </div>

//                             <div className='row'>
//                                 <div className='col-lg-4 offset-lg-1'>

//                                     <Button
//                                         loading={loading}
//                                         size='large' block type="primary" htmlType="submit">
//                                         {loading ? 'جاري ...' : 'التالي'}
//                                     </Button>
//                                 </div>
//                                 <div className='col-lg-4 offset-lg-1'>
//                                     <Button size='large' block>
//                                         <Link className='d-block text-decoration-none' to='/add-property/step-5'> رجوع </Link>
//                                     </Button>

//                                 </div>

//                             </div>

//                         </Form>
//                         :
//                         <div className='text-center'>
//                             <Spin size="large" />
//                         </div>

//                 }

//             </div>
//         </div>
//     );
// }
