import React, { useEffect, useState } from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { del, get, patch, put } from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";
import { checkPermission } from "../auth/ProtectedRoutes";
import OrderDropdown from "./Dropdown";

const HomeSliders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get("home-sliders").then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  }, []);

  const allOrders = Array.from(new Set(data.map((item) => item.order))).sort();

  const handleOrderChange = (id, currentOrder, selectedOrder) => {
    const steps = Math.abs(currentOrder - selectedOrder);
    const direction = currentOrder > selectedOrder ? "up" : "down";

    patch(`home-sliders/order/${id}`, { steps, direction })
      .then(() => {
        message.success("تم تغيير الترتيب بنجاح");
        get("home-sliders").then((res) => setData(res.data.data));
        // window.location.reload();
      })
      .catch((error) => {
        message.error("حدث خطأ أثناء تغيير الترتيب");
        console.error(error);
      });
  };
  const navigate = useNavigate();

  const handleEditClick = (record) => {
    navigate(`/home-sliders/edit/${record.id}`, {
      state: { sliderData: record },
    });
  };
  const columns = [
    {
      title: "الصورة",
      dataIndex: "path",
      render: (path) => (
        <img
          src={path}
          alt="alt"
          className="rounded"
          style={{ height: "100px", width: "100px", objectFit: "cover" }}
        />
      ),
    },
    {
      title: "العنوان",
      render: ({ title }) => <>{title || "-"}</>,
    },
    {
      title: "الوصف",
      render: ({ description }) => <>{description || "-"}</>,
    },
    {
      title: "الترتيب",
      render: ({ id, order }) => {
        const filteredOrders = allOrders.filter((o) => o !== order);
        return (
          <OrderDropdown
            order={order || "-"}
            allOrders={filteredOrders}
            onOrderSelect={(selectedOrder) =>
              handleOrderChange(id, order, selectedOrder)
            }
          />
        );
      },
    },
    {
      title: "تعديل",
      render: (record) => (
        <Button type="primary" block onClick={() => handleEditClick(record)}>
          تعديل
        </Button>
      ),
    },
  ];

  if (checkPermission("delete-home-slider")) {
    columns.push({
      title: "حذف",
      dataIndex: "id",
      render: (id) => (
        <Popconfirm
          title="حذف العنصر"
          description="هل أنت متأكد من حذف هذه العنصر؟"
          onConfirm={() => confirm(id)}
          onCancel={cancel}
          okText="نعم"
          cancelText="لا"
        >
          <Button danger>حذف</Button>
        </Popconfirm>
      ),
    });
  }

  const handleDelete = (id) => {
    del(`home-sliders/${id}`).then(() => {
      setData((prevData) => prevData.filter((item) => item.id !== id));
      message.success("تم الحذف بنجاح");
    });
  };

  const confirm = (id) => handleDelete(id);
  const cancel = () => message.error("تم إلغاء الأمر");

  return (
    <div>
      <div className="row">
        {checkPermission("create-home-slider") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
              className="text-decoration-none d-block h-100"
              to="/home-sliders/add"
            >
              <Button type="primary" block>
                إضافة
              </Button>
            </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{ y: "65vh", x: 650 }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSliders;
