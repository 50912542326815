import React, { useEffect, useState } from 'react';
import { post, postFromData } from '../../../../api/axios';
import { Button, Checkbox, Divider, Form, Input, InputNumber, message, Radio, Space, Spin, Typography } from 'antd';
import Steper from '../layoutProperty/Steper';
import { Link, useNavigate } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const Step9 = () => {
    const [loading, setLoading] = useState(false);
    const [fromloading, setFromLoading] = useState(false);
    const { Paragraph, Text } = Typography;
    const { TextArea } = Input;
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState({
        is_agreed: ''
    })
    const [agreement_text, setagreement_text] = useState(null)

    useEffect(() => {
        // return () => {

        if (localStorage.getItem('codeAddProperty')) {
            console.log(localStorage.getItem('codeAddProperty'))
            postFromData('add-property/step-9', { code: localStorage.getItem('codeAddProperty') }).then(res => {
                // setinputsValue(res.data)
                setFromLoading(true)
                setagreement_text(res.data.agreement_text)

            })
        } else {
            navigate('/add-property/step-1')
        }
        // }
    }, []);


    const onFinish = (values) => {
        console.log('values', values);
        if (inputsValue.is_agreed) {

            setLoading(true);

            post('add-property/step-9', { 'code': localStorage.getItem('codeAddProperty'), ...inputsValue }).then(res => {
                setLoading(false);
                // navigate('/add-property/step-1')
                message.success('تم اضافة العقار بنجاح!')
                setTimeout(() => {

                    localStorage.removeItem("codeAddProperty")
                    window.location.href = '/'
                }, 1000);
            }).catch(err => {
                message.error(err.response.data.message);
                setLoading(false);
            })
        } else {
            message.error('الرجاء الموافقة اولا')
        }

    };



    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
        // setis_agreed(e.target.checked)
        // setinputsValue({ is_agreed: e.target.checked ? e.target.checked : '' })
        setinputsValue({ is_agreed: e.target.checked })
    };



    return (
        <div className='add-prop step-9'>
            <Steper current={9} />


            {
                fromloading ?
                    <Form
                        onFinish={onFinish}
                        // labelCol={{
                        //     span: 10,
                        // }}
                        wrapperCol={{
                            span: 21,
                        }}

                        initialValues={{
                            "is_agreed": inputsValue.is_agreed,
                        }}
                    >

                        <div className='row'>
                            <div className='col-lg-12'>
                                <Divider orientation="left" className='text-main'> <span className='text-main'> إتفاقية إستخدام منصة مكان   </span> </Divider>

                                <Form.Item
                                    name='is_agreed'
                                >
                                    <Checkbox value={inputsValue.is_agreed} onChange={onChange}>
                                        <div dangerouslySetInnerHTML={{ __html: agreement_text }} />
                                    </Checkbox>
                                </Form.Item>

                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-4 offset-lg-1'>

                                <Button
                                    loading={loading}
                                    size='large' block type="primary" htmlType="submit">
                                    {loading ? 'جاري ...' : 'التالي'}
                                </Button>
                            </div>
                            <div className='col-lg-4 offset-lg-1'>
                                <Button size='large' block>
                                    <Link className='d-block text-decoration-none' to='/add-property/step-7'> رجوع </Link>
                                </Button>

                            </div>

                        </div>
                    </Form>


                    :
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>

            }
        </div>
    );
}

export default Step9;
