import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Form,
  Radio,
  DatePicker,
} from "antd";
import { get, postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const { Title } = Typography;

const AddOwner = () => {
  const navigate = useNavigate();

  const [inputsValue, setinputsValue] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    dob: "",
    gender: "",
    nationality_id: "",
    residence_type: "",
    id_number: "",
  });
  const [dataRegions, setNationalities] = useState([]);
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(
      dataRegions.map((d) => ({
        value: d.id,
        label: d.nationality_ar,
      }))
    );
  }, [dataRegions]);

  useEffect(() => {
    get("get-all-nationalities").then((res) => {
      setNationalities(res.data);
    });
  }, []);

  const handelResidence = (value) => {
    console.log(`selected ${value}`);
    setinputsValue({ ...inputsValue, residence_type: value });
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleChange = (e) => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const handleGender = (e) => {
    setinputsValue({ ...inputsValue, gender: e.target.value });
  };

  const handleNationality = (value) => {
    setinputsValue({ ...inputsValue, nationality_id: value });
  };

  const handledob = (date, dateString) => {
    console.log("b1", dateString);
    console.log("b2", date);
    setinputsValue({ ...inputsValue, dob: dateString });
    // setinputsValue(prev => ({...prev,dob: dateString}))
  };
  console.log("state", inputsValue);

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    postFromData("owners", inputsValue)
      .then((res) => {
        message.success("تم الإضافة بنجاح");
        setLoading(false);
        navigate("/owners");
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        إضافة مالك جديد{" "}
      </Title>
      <Form
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 15,
        }}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-4">
            <Form.Item
              label="الاسم"
              name="name"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              label="النوع"
              name="gender"
              value="1"
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
              ]}
            >
              <Radio.Group onChange={handleGender}>
                <Radio value="1">ذكر</Radio>
                <Radio value="2">انثي</Radio>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              label="البريد الإلكتروني"
              name="email"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
                {
                  type: "email",
                  message: "هذا البريد الإلكتروني غير صحيح!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
              label="الجوال"
              name="phone"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
                {
                  min: 10,
                  message: "يجب ان يكون اكتر من 10 ارقام",
                },
              ]}
            >
              {/* <Input type='number' /> */}
              <PhoneInput />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
              label="العنوان"
              name="address"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
              label="تاريخ الميلاد"
              name="dob"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
              ]}
            >
              <DatePicker
                placeholder=""
                className="w-100"
                onChange={handledob}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              label="الجنسية"
              name="nationality_id"
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
              ]}
            >
              <Select
                className="w-100"
                showSearch
                placeholder="حدد الجنسية"
                optionFilterProp="children"
                onChange={handleNationality}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={option}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              label="نوع الإقامة"
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
              ]}
              name="residence_type"
            >
              <Select
                className="w-100"
                placeholder="نوع الإقامة"
                onChange={handelResidence}
                options={[
                  {
                    value: "1",
                    label: "مواطن",
                  },
                  {
                    value: "2",
                    label: "مقيم",
                  },
                  {
                    value: "3",
                    label: "سائح",
                  },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              label="رقم الهوية"
              name="id_number"
              onChange={handleChange}
              rules={[
                {
                  required: true,
                  message: "هذا الحقل مطلوب!",
                },
                {
                  min: 10,
                  message: "يجب ان يكون اكتر من 10 ارقام",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </div>

          <div className="col-lg-4 mt-3 offset-lg-4">
            <Form.Item
              wrapperCol={{
                offset: 1,
                span: 16,
              }}
            >
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "جاري الإضافة..." : "إضافة"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddOwner;
