const StatisticItem = ({ label, value }) => (
  <div className="col-lg-4 mb-2 mb-lg-4">
    <div className="row">
      <div className="col-8">
        <div className="label">{label} :</div>
      </div>
      <div className="col-4">
        <div className="value">{value ?? "N/A"}</div>
      </div>
    </div>
  </div>
);
export default StatisticItem;
